import React, { Component } from 'react';

import lemonIcon from '../images/lemon-icon.png';

import Marquee from  './common/marquee'

class Header extends Component {
  render() {
    return (
      <header>
        <div className="logo logo--pc">
          <img src={lemonIcon} alt="lemon-icon" />
          <h1>2018 AVAT X Arts School for Youth Online Curatorial Project</h1>
        </div>
        <div className="logo logo--mobile">
          <div className="logo--mobile__logo">
            <img src={lemonIcon} alt="lemon-icon" />
            <img src={lemonIcon} alt="lemon-icon" />
            <img src={lemonIcon} alt="lemon-icon" />
            <img src={lemonIcon} alt="lemon-icon" />
            <img src={lemonIcon} alt="lemon-icon" />
            <img src={lemonIcon} alt="lemon-icon" />
          </div>
          <Marquee text="2018 AVAT X Arts School for Youth Online Curatorial Project" />
        </div>
      </header>
    );
  }
}

export default Header;
