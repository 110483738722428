import React from 'react';

import '../../scss/work-section/index.scss';

import WorkSectionMain from  './workSectionMain'
import HuangWork from  './huangWork'
import TsaiWork from  './tsaiWork'
import ChiaWork from  './chiaWork'

import huangIdImg from '../../images/common/huang-id.jpg';
import tsaiIdImg from '../../images/common/tsai-id.jpg';
import jiaIdImg from '../../images/common/jia-id.jpg';


const HuangWorkIntro = `
  <p>2013年，黃博志開始了《五百棵檸檬樹》計畫。藉由500棵檸檬樹的種植，他建構了一個集體參與和自我實現的場域。在長達二年的時間，500名主動參與者以500元加入認購行列，而透過「認購行動」讓他們成為了計畫的「協作者」，二年之後拿到一瓶藝術家所釀造的檸檬酒，就像是創作者與參與者集體通過類似信仰的儀式，終於實現了將藝術轉化為生活實踐的夢想。</p>
  <p>黃博志是個感性的藝術家，他的作品反射出那種來自生命底層沸騰的、騷動的、哀傷的、快樂的氣氛。他把這個種植計畫比喻為一場有劇本、有舞台、有演員、有台詞的「行動話劇」，他說：「不論搶劫銀行或是執行藝術計畫，都很像一場行動話劇，點燈之後，我們都被光引領著前進，甚至被推移著、催促著，也急急忙忙扮演著。每當酒一口喝下，輕輕刮過食道，酒所留下的短暫滾燙痕跡，有人說那是酒中乍現的精靈，也有人說那是穀物的靈魂，我更相信那是存在於酒中的光。」《五百棵檸檬樹》把我們帶回人生舞台，這場表演是俗世的，它的感動就是相信藝術可以觸及「生命」，唯有把最真實的生命演出融入作品裡，才是藝術最深刻的表現力。</p>
  <br />
  <p class="en">It was in 2013 that Po-Chih Huang began his project “Five Hundred Lemon Trees.” By planting 500 lemon trees, he created a domain of collective participation and personal realization. Over the course of two years, 500 volunteer participants joined the ranks of his patrons, with a subscription of NT$500 each. This “subscription campaign” allowed them to become “collaborators.” After two years, they received a bottle of lemon spirits concocted by the artist himself. It was as if the artist and the participants, through a ritual of faith, finally realized the dream of transforming art into a living practice.</p>
  <p class="en">Po-Chih Huang is an artist of the senses. His work reflects the kind of boiling, roiling, sad, happy atmosphere arising from the lower strata of life. He likens his agricultural project to an “action drama,” with a script, stage, actors and lines. He says: “Robbing a bank and carrying out an art project are both like an action drama. When the lights go on, we’re all drawn forward, even pushed, urged, acting in a big hurry. When a mouthful of liqueur gently scratches the esophagus, some people say that brief burning trace left by the spirits is the sudden appearance of the fairy in the alcohol. Some people say it is the soul of the grain. I believe it is the light that resides in the wine.” Five Hundred Lemon Trees took us back to the theater of life. It was an earthy performance. Its motive force was the belief that art can connect with “life.” Only when a supremely realistic performance of life becomes an integral part of a work does the deepest expressive power of art emerge.</p>
`
const TsaiWorkIntro = `
  <h5>故事</h5>
  <p>是夜，他撕扯著布，將扯下的布條浸水染色。非常鮮豔的染料連同他的手一起染成各種顏色，最後混成濁褐泥土碎著青苔的一雙手，好像很難洗掉了。</p>
  <p>另一人幫他將漲滿水沈甸甸的布條從缸裡撈起來擰乾，這動作讓他們滿身是汗，在逐漸日出的透光下樹梢迷迷惘惘騷擾著天際線。</p>
  <p>兩人四手默契地擰著纏繞成結的強韌對手，吃力中有滿足，像寫了一首好歌詞也得那樣同字詞搏鬥。日頭漸亮，沁涼地透出藍；夜還不到停，從西方頓頓拖著裙襬走，留下一片角落給魑魅魍魎返家。顏料繼續滴落，溪谷的泥土像吸血鬼滿口血，身心舒暢，散發出泥土澀澀的礦物香，刺激的氣味隨溫度向上擴散，衝進他的孔竅，一下清醒。</p>
  <p>他們將成團布條掛起，顏色液體滴進泥土被吃了進去，在飽足的胃外集成一灘小湖。</p>
  <p>他拍拍髒了的手，在衣服上留下手印，無法辨識哪道是哪根指頭。中指，食指，無名指，小指。全都同衣服的皺摺內內外外疊合在一起。</p>
  <p>被解開的布條從高高立起的竿子上像披頭散髮的女人垂掛，看起來哀怨重重臨水躑躅，不知從哪家逃出，看似束手就擒。</p>
  <p>兩人將那兩塊早就準備好的純白的四方板，用力搬到竿子旁，用背或者用手，活脫脫兩個走向死亡的耶穌，表情凜然。四方板子立在竿子兩側，啪一聲照亮景色。那白太白，邊緣銳利，衝散一切神秘小鬼，理性大開。他們開始將滴水的長髮女人布條逐一分開，女人哀怨地唱，尖聲：苦啊-----，拖長的語尾像星火墜落。布條一一被釘在板上，兩人動作笨拙，沒有演練過如何敲打釘子，只知道埋首工作。</p>
  <p>布條從竿子頂端張開，魂飛魄散疲軟橫陳，女人大開的身體在純白的板上滴下顏料，過好久才終於安歇下來。</p>
  <p>鳥兒啁啾叫。</p>
  <p>那個時候，白色在人們心中還是一種想像中的顏色，那樣刺眼的白色是不存在的顏色，沒有人見過。一般人所謂的白色總是被物質留下頑固的存在證據，佈滿生活。布帛瓷器參雜著斑點，昭告日常如常，會被弄髒也可以洗淨。那個時候阿爸阿母還會哄唱睡眠曲，夜長且靜，溪谷有靈魂。長長的空白與沈默還是必要質素，山陵線切過視線如高聳一道牆畫出世界邊緣。</p>
  <p>他看天已全亮，轉頭凝望溪谷，背對張開的布條，身體處在隨時用力的前一刻，一種待張狂未張狂的形，但等不到動身的時點。另一人側身恍惚進入同樣的靜止，看起來像兩尊準備從夢中撤退的敗兵雕像，氣息尚存卻逐漸失去身體。他們看起來在時間中不會流動，或者永遠慢了一步，越來越慢，逐漸慢出日常，逐漸不被看到。天照常亮，那隻布條蜘蛛或張髮的美杜莎站立著，一切都很安靜，暫時之間除了日常以外一切都不動。</p>
  <br />
  <h5>對白</h5>
  <p>Ａ：在剛才的過程中，你覺得......</p>
  <p>Ｆ：白色.....</p>
  <p>Ａ：你覺得......</p>
  <p>Ｆ：一種單純的力量......</p>
  <p>Ａ：最好了。</p>
  <p>Ｆ：是的！我一直相信純粹是一種極限......</p>
  <p>Ａ：你會讚美！</p>
  <p>Ｆ：對！換句話說純粹，純而簡單是......最......最......最......</p>
  <p>Ａ：可以獲得你的稱許......</p>
  <p>Ｆ：偉大而崇高！崇高而偉大......</p>
  <p>Ａ：是不是請你......</p>
  <p>Ｆ：我？是！</p>
  <p>Ａ：請你稍微......</p>
  <p>Ｆ：我？</p>
  <p>Ａ：解釋一下。</p>
  <p>Ｆ：對！偉大的統一，統一是偉大的。</p>
  <p>Ａ：你聽見我在說話嗎？</p>
  <p>Ｆ：對！</p>
  <p>Ａ：我在和你說話。</p>
  <p>Ｆ：我知道了，</p>
  <p>Ａ：你知道了？真難得。</p>
  <p>Ｆ：難得的是，我知道了。</p>
  <p>Ａ：你知道了。</p>
  <p>Ｆ：我知道了。</p>
  <p>Ａ：我在和你說話。</p>
  <p>Ｆ：對！</p>
  <p>Ａ：你聽見我在說話嗎？</p>
  <p>Ｆ：對！偉大的統一，統一是偉大的。</p>
  <p>Ａ：解釋一下。</p>
  <p>Ｆ：對。</p>
  <p>Ａ：請你稍微.....</p>
  <p>Ｆ：我？是。</p>
  <p>Ａ：是不是請你。</p>
  <p>Ｆ：偉大而崇高，崇高而偉大</p>
  <p>Ａ：可以獲得你的稱許</p>
  <p>Ｆ：對，換句話說，純粹，純而簡單是最......最......</p>
  <p>Ａ：你會讚美？</p>
  <p>Ｆ：是的！我一直相信純粹是一種極限</p>
  <p>Ａ：最好了</p>
  <p>Ｆ：一種單純的力量</p>
  <p>Ａ：你覺得？</p>
  <p>Ｆ：白色！</p>
  <p>Ａ：在剛才的過程中你覺得？</p>
  <p>Ｆ：不錯！是白色。當整個空間只有那兩個從頭到腳都是白色的人時......</p>
  <p>Ａ：都是白色的人的時候，那兩個人......</p>
  <p>Ｆ：那兩個不是人，是一個個體，一個有機個體。</p>
  <p>Ａ：一個有機個體意喻著化學變化的可能性</p>
  <p>Ｆ：可能會變得比較純粹。</p>
  <p>Ａ：純粹沒有異質性的東西存在。</p>
  <p>Ｆ：沒有異質性的東西存在是純粹。</p>
  <p>Ａ：純粹比較有變的可能。</p>
  <p>Ｆ：可能是一種化學性的有機體。</p>
  <p>Ａ：一個有機體，一個個體，不是那兩個人。</p>
  <p>Ｆ：都是白色的兩個人。</p>
  <p>Ａ：有機體。</p>
  <p>Ｆ：你快發現了。</p>
  <p>Ａ：發現了我們的對話是可以倒過來的，一直到現在。</p>
  <p>Ｆ：此因白馬非馬。</p>
  <p>Ａ：馬非白馬，因此。</p>
  <p>Ｆ：可也！</p>
  <p>Ａ：也可！</p>
  <p>Ｆ：你發現的不應是這個。</p>
  <p>Ａ：這個不應是被發現的。</p>
`
const ChiaWorkIntro = `
  <p>「城市」這件作品由兩個成分組成，一是利用在柏林、倫敦、巴黎、奧克蘭、曼谷等國旅行時所拍攝「看起來像是台北的照片」，二是在台北街頭所拍攝「看起來像是國外的照片」夾雜所建構出來的城市印象。</p>
  <p>模糊各城市物理位置，意圖討論「物品」和「城市」這兩個辭彙之間關係上的準確度。透過旅行當中隨拍的照片為這個「城市」做鋪陳，以自身所生長的城市——台北為出發點，將各國城市可見的地方性辨識度往下沿伸到難以果斷辨識的地步，錯置出一座可能是任何的地方也可能不是任何地方的「城市」。</p>
  <br />
  <p class="en"><i>#mycity</i> is composed by two sets of pictures, the one taken in Berlin, London, Paris, Oakland, Bangkok while traveling which look like images of Taipei, and the other taken in Taipei as though pictures of foreign city instead.</p>
  <p class="en">By confusing the geographic location, the artist attempts to challenge the referring relationship between “object” and “city”. Starting off from Taipei, where the artist has been living, she mixes up snapshots taken while traveling and makes the visible characteristics of different cities unrecognizable. Through displacement, the work constructs an image of “city”, which could be referred to either everywhere or nowhere.</p>
`

const worksInfo = ({ id, click }) => {
  switch(id) {
    case '500-Lemon-Trees':
      return (
        <WorkSectionMain
          id="Huang-Po-Chih" 
          backgroundImage={huangIdImg}
          artistEn="HUANG PO-CHIH"
          artistTw="黃博志"
          workIntro={HuangWorkIntro}
          click = {click}
        >
          <HuangWork />
        </WorkSectionMain>
      );
    case 'The-White-Dew-Turns-into-Hoarfrost':
      return (
        <WorkSectionMain 
          id="Tsai-Wan-Chieh" 
          backgroundImage={tsaiIdImg}
          artistEn="TSAI WAN-CHIEH"
          artistTw="蔡宛潔"
          workIntro={TsaiWorkIntro}
          click = {click}
        >
          <TsaiWork />
        </WorkSectionMain>
      );
    case 'My-City':
      return (
        <WorkSectionMain
          id="Chia-Chieh-Ju" 
          backgroundImage={jiaIdImg}
          artistEn="CHIA CHIEH-JU"
          artistTw="賈茜茹"
          workIntro={ChiaWorkIntro}
          click = {click}
        >
          <ChiaWork />
        </WorkSectionMain>
      );
    default:
      return 'Error';
  }
}

const WorkSection = (props) => {
  return (
    <div className="work-section">
      { worksInfo(props) }
    </div>
  );
}

export default WorkSection;