import React from 'react';

import '../../scss/index/banner.scss';

import logo from '../../images/logo.png';
import subTitleVertical from '../../images/banner-subtitle-vertical.svg';
import subTitleHorizon from '../../images/banner-subtitle-horizon.png';
// import gameIcon from '../../images/game-icon.png';

const Banner = () => {
  return (
    <div className="banner border">
      <div className="banner__top">
        <div className="banner__top__logo">
          <img src={logo} alt="翻掘指南" />
        </div>
        <div className="banner__top__title banner__top__title--vertical">
          <img src={subTitleVertical} alt="2018線上策展徵件計畫" />
        </div>
        <div className="banner__top__title banner__top__title--horizon">
          <img src={subTitleHorizon} alt="2018線上策展徵件計畫" />
        </div>
      </div>
      <div className="banner__bottom">
        <div className="banner__bottom__text">
          <h6>
            Re:<br />
            Read<br />
            And<br />
            Interpretation<br />
          </h6>
        </div>
        <div className="banner__bottom__game">
          {/* <img src={gameIcon} alt="game-icon" /> */}
          <div className="space-invader space-invader-1 classic"></div>
          <div className="space-invader space-invader-2 classic"></div>
          <div className="space-invader space-invader-3 classic"></div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
