import React, { Component } from 'react';

import '../../scss/artist-section/index.scss';

const TsaiPingJu = `
  <h3>策展人．蔡秉儒</h3>
  <p>1993年生於台南。目前就讀國立台北教育大學藝術與造形設計學系碩士班，藝術理論與評論組。</p>
  <p>大學主修金屬雕塑，研究所則轉向理論，期許自己有著更流動的創作管道與更多重的藝術實踐。</p>
  <p>當前關注島嶼上的認同議題，試圖在單一正確價值中，參照個體多元生命敘事，於二元分壘的意識型態大道旁，闢掘一條條曲折小徑。</p>
  <br />
  <p class="en">Born in Tainan in 1993, Tsai Ping-Ru is a student of M.F.A Program, Department of Art and Design, National Taipei University of Education.</p>
  <p class="en">From majoring in metal sculpture during college to now specializing in Art Theory and Critique, the turn implies his expectation to have more flexibility and openness in different forms of creation and multiple art practices.</p>
  <p class="en">Tsai currently focuses on the subject of identity. He tries to pay close attention to various narratives of individuals’ lives under the single correctness of certain value, in order to dig tortuous paths alongside the Avenue of Ideology.</p>
`;
const HuangpoChih = `
  <h3>藝術家．黃博志</h3>
  <p>黃博志畢業於台北藝術大學新媒體藝術學系，在他多元化的藝術實踐，著重探討的是農業、製造業、生產、消費等議題，皆與其個人家庭處境和家族歷史息息相關。</p>
  <p>二零一三年出版散文集《藍色皮膚：老媽的故事》，透過記錄他的母親，一個平凡個體的職業流變，去間接反映台灣近五十年來的社會改革與經濟變遷。</p>
  <p>在他近年持續性的創作計畫《五百棵檸檬樹》中，將展覽作為募資平台，一方面挪用藝術世界的資源發展農業品牌、活化廢耕地、種植檸檬樹並釀造檸檬酒；另一方面則聯繫家族成員、當地農夫、消費者，產生新的社群關係。</p>
  <br />
  <p class="en">Po-Chih Huang’s diverse artistic practice revolves around the circumstances and history of his family which enable him to involve in issues like agriculture, manufacturing, production, consumption, etc.</p>
  <p class="en">Since 2013, exhibitions of his continuous art project Five Hundred Lemon Trees have been transformed to a crowd funding platform allowing the appropriation of artistic resources for developing an agricultural brand, activating fallow farmland, and growing lemon trees for lemon liquor.</p>
  <p class="en">On the other hand, the project has connected his family members, local farmers and consumers to make a new social relationship possible. In the same year, he published his first collection of essays Blue Skin—All About My Mother《藍色皮膚：老媽的故事》, the story about his mother.</p>
  <p class="en">In a way, such a brief account of personal history can somehow reflect Taiwan's agriculture economic reform and social change over the past fifty years, which is essentially, a micro-level of observing his own family history and society as a whole in Taiwan.</p>
`
const TsaiWanChieh = `
  <h3>藝術家．蔡宛潔</h3>
  <p>現工作，居住於台北。</p>
  <p>作品關注文字或口說語言在當代生活情境下的挪用、傳播、誤讀與象徵意義的游移。過往作品結合訪談、劇場、文學、繪畫、文件等不同形式媒材執行計畫，包括對世界末日進行大規模問卷調查、收集展名並編寫為流行歌曲、重新以文字演繹劇本並加以延伸等。我想做的是21世紀的當代神秘主義心靈研究，特別是文學與神話的當代想像。</p>
  <ol>
    <li>探究人類的集體意識如何影響大眾文化現象。</li>
    <li>實驗文本與影像間的關聯，以及如何觸發觀眾的感性與想像。</li>
    <li>靈性意識、神秘學、宗教象徵系統的圖像學研究，以及在藝術上的可能性。</li>
  </ol>
  <br />
  <p class="en">TSAI Wan Chieh currently lives and works in Taipei.</p>
  <p class="en">She has been focusing on how written or verbal language is translated, converted, disseminated, misunderstood in everyday life and the ambiguity of symbolic meanings. Her past art projects encompassed different forms and media such as interviews, theater pieces, literatures, paintings and documents.</p>
  <p class="en">The artist for instance, conducted a large-scale survey on the end of the world, gathered exhibition titles to write popular songs, and reinterpreted scripts. What she has attempted to put into practice is research on mysticism and parapsychology in 21st century, especially contemporary thoughts on literature and mythology.</p>
  <br />
  <ol>
    <li class="en">Investigating how collective consciousness would affect phenomena of popular culture.</li>
    <li class="en">Experimenting the relation between text and image, and the ways to trigger the audience’s sensibility and imagination.</li>
    <li class="en">Exploring the art potential of iconology relating to research on spiritual consciousness, mysticism and the symbolic order of religion. </li>
  </ol>
`
const ChiaChiehJu = `
  <h3>藝術家．賈茜茹</h3>
  <p>賈茜茹1984年於台北出生，2012年碩士畢業於國立臺北藝術大學美術創作學系，現居於台灣生活及創作。</p>
  <p>2012年以「塑膠袋們」一作品獲得高雄獎首獎，2013年3-6月駐村於紐西蘭，並舉辦個展“CITY COLOR”。2014-2016專注創作「大勇街，25巷」系列作品，其中「大勇街25巷，末章。」於2016年獲得台新藝術獎第十五屆提名。2017年2-4月受菲律賓當代藝術機構「98B」邀請，得文化部補助，駐村於菲律賓並舉辦個展“SMile”。2018年4-5於澳洲RMIT駐村，並舉辦個展“CITY COLOUR_Balanced Gray : Crystal of Shadow of Stone of…”。</p>
  <p>創作上，擅長觀察尋找當地的現成物，而後混合在地生活經驗，將物件再現與重製，製造某種相較於真實生活的變異場景。</p>
  <br />
  <p class="en">Chia Chien-Ju was born in 1984 in Taipei City. In 2012, she graduated from the National Taipei University of Arts where with a Master of fine Art. She lives and works in Taiwan.</p>
  <p class="en">In 2012, won the first prize of the Kaohsiung Awards by the “PLACTIC BAGS”. In 2013 March to June, she had an artist residency in New Zealand and hold a solo exhibition-" CITY COLOR ". In 2014-2016, she creation focused on the series of “ Ln. 25, Dayong St., “ and one of this series “ The Last Chapter of Ln. 25, Dayong St., “ got a nomination of Tai-shin Arts Award 2016. In 2017 February to April, she was invited by 98B Collaboratory, won the subsidy funds of Minister of Culture of Taiwan to fulfill an artist residency in Philippines. In 2018 April to June, she had an artist residency in Australia and hold a solo exhibition-" CITY COLOUR_Balanced Gray : Crystal of Shadow of Stone of… ".</p>
  <p class="en">In her creation, she observe and search for local ready-made objects, then in combination with the local living experience, remake these objects seen in real life, and present them in a new manner to create scenes that might be considered bizarre in comparison with real life settings.</p>
`

const profile = (value) => {
  switch(value) {
    case 'Tsai-Ping-Ju':
      return TsaiPingJu;
    case 'Huang-Po-Chih':
      return HuangpoChih;
    case 'Tsai-Wan-Chieh':
      return TsaiWanChieh;
    case 'Chia-Chieh-Ju':
      return ChiaChiehJu;
    default:
      return 'Error';
  }
}

class ArtistSection extends Component {
  constructor(props) {
    super(props);
    this.handleMoreClick = this.handleMoreClick.bind(this);
  }

  handleMoreClick() {
    this.props.moreClick(this.props.artistId)
    document.getElementsByClassName('modal-section')[0].scrollTop = 0;
  }

  render () {
    const { artistId } = this.props;
    return (
      <div className="artist-intro">
        <div 
          id={ artistId === 'Tsai-Ping-Ju' ? 'curator-intro' : 'artist-intro'}
          className="artist-intro__text custom-scroll scroll-light" 
          dangerouslySetInnerHTML={{ __html: profile(artistId)}}>
        </div>
        {
          artistId !== 'Tsai-Ping-Ju' &&
          <div className="artist-intro__more" onClick={this.handleMoreClick}>
            <div className="artist-intro__more__link">
                <h3>Artwork</h3>
            </div>
            <div className="artist-intro__more__arrow"></div>
          </div> 
        }
      </div>
    );
  }
}

export default ArtistSection;
