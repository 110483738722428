import React, { Component } from 'react';

class Work extends Component {
  constructor(props){
    super(props);
    this.state= {
      currentId: props.id
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
  	this.props.click(this.state.currentId)
  }
  
  render() {
    const {
      backgroundImage,
      linkColor,
      titleEn,
      titleTw,
      artist
    } = this.props;

    return (
      <div 
        className="work"
        style={{ backgroundImage: `url(${backgroundImage})` }}
        onClick={this.handleClick}
      >
        <div className="work__intro">
          <h3 dangerouslySetInnerHTML={{__html: titleEn}}></h3>
          <div className="work__intro__name">
            <h5>{ titleTw }</h5>．<h5>{ artist }</h5>
          </div>
        </div>
        <div 
          className="work__link"
          style={{ backgroundColor: linkColor }}
        ></div>
      </div>
    );
  }
}
  
export default Work;
