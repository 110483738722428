import React  from 'react';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'

import 'react-id-swiper/src/styles/css/swiper.css';

import WorkTextChia from '../../images/work-text-chia.png';
import ChiaWork1 from '../../images/chia/chia-work-1.jpg';
import ChiaWork2 from '../../images/chia/chia-work-2.jpg';
import ChiaWork3 from '../../images/chia/chia-work-3.jpg';
import ChiaWork4 from '../../images/chia/chia-work-4.jpg';
import ChiaWork5 from '../../images/chia/chia-work-5.jpg';
import ChiaWork6 from '../../images/chia/chia-work-6.jpg';
import ChiaWork7 from '../../images/chia/chia-work-7.jpg';
import ChiaWork8 from '../../images/chia/chia-work-8.jpg';
import ChiaWork9 from '../../images/chia/chia-work-9.jpg';

const images = [
  { 
    image: ChiaWork1,
    url: 'https://www.instagram.com/p/Bq2vmOBAI2W/'
  },
  {
    image: ChiaWork2,
    url: 'https://www.instagram.com/p/Bq20337hzC1/'
  },
  {
    image: ChiaWork3,
    url: 'https://www.instagram.com/p/Bq7iabIhn6L/'
  },
  {
    image: ChiaWork4,
    url: 'https://www.instagram.com/p/BuFEl3RnoZv/'
  },
  {
    image: ChiaWork5,
    url: 'https://www.instagram.com/p/BuMtUd0HyAR/'
  },
  {
    image: ChiaWork6,
    url: 'https://www.instagram.com/p/BuUC2GFnuST/'
  },
  {
    image: ChiaWork7,
    url: 'https://www.instagram.com/p/BuiAytED2eT/'
  },
  {
    image: ChiaWork8,
    url: 'https://www.instagram.com/p/Bu_GxfQj44T/'
  },
  {
    image: ChiaWork9,
    url: 'https://www.instagram.com/p/Bu_HUKWjkRi/'
  }
];

const slider = images.map((item, i) => {
  return (
    <div 
      key={i} 
      className="image-item" 
    > 
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <img src={item.image} alt="我的城市" />
      </a>
    </div>
  );
})

export const SimpleSwiperWithParams = () => {
  const params = {
    modules: [Pagination, Navigation],
    pagination: {
      el: '.swiper-pagination.customized-swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next.customized-swiper-button-next',
      prevEl: '.swiper-button-prev.customized-swiper-button-prev'
    },
    containerClass: 'customized-swiper-container',
    slidesPerView: 3,
    spaceBetween: 50,
    slidesPerGroup: 3,
    loop: true,
    loopFillGroupWithBlank: true,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      576: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  }

  return(
    <Swiper {...params}>
      {slider}
    </Swiper>
  )
}

const ChiaWork = () => {
  return (
    <div className='chia-work'>
      <div className='chia-work__title'>
        <img src={WorkTextChia} alt="我的城市" />
      </div>
      <div className="chia-work__slider">
        <SimpleSwiperWithParams />
      </div>
    </div>
  );
}

export default ChiaWork;