import React, { Component } from 'react';

import '../scss/app.scss';

import brush from '../images/brush.png';

import Header from  './header'
import Banner from  './index/banner'
import Main from  './index/main/index'
import Marquee from  './common/marquee'
import ArtistRow from  './index/artistRow'
import About from  './index/about'
import Footer from  './footer'

class App extends Component {
  render() {
    const marqueetext = `Artist<img src=${brush} />`
    return (
      <div className="App">
        <Header />
        <Banner />
        <Main />
        <Marquee text={marqueetext} />
        <ArtistRow />
        <About />
        <Footer />
      </div>
    );
  }
}

export default App;
