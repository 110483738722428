import React, { Component } from 'react';

import '../../scss/common/subnav.scss';

import BitTree from '../../images/common/bit-tree.png';
import BitClap from '../../images/common/bit-clap.png';
import BitScreen from '../../images/common/bit-screen.png';

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          id: '500-Lemon-Trees',
          img: BitTree,
          text: '500 Lemon Trees'
        }, 
        {
          id: 'The-White-Dew-Turns-into-Hoarfrost',
          img: BitClap,
          text: 'The White Dew Turns into Hoarfrost'
        }, 
        {
          id: 'My-City',
          img: BitScreen,
          text: 'My City'
        }
      ]
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
  	this.props.click(id)
  }

  render () {
    return (
      <div className="subnav">
        {this.state.items.map((item, i) => 
          <div key={i} data-id={item.id} className="subnav__item" onClick={() => this.handleClick(item.id)}>
            <img src={item.img} alt="The White Dew Turns into Hoarfrost" />
            <h3>{item.text}</h3>
          </div>
        )}
      </div>
    );
  }
}

export default SubNav;
