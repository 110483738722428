import React, { Component } from 'react';

import '../../scss/artist-section/index.scss';

import ArtistCard from  '../common/artistCard'
import ArtistIntro from  '../artistSection/artistIntro'

import curatorIdImg from '../../images/common/curator-id.jpg';
import huangIdImg from '../../images/common/huang-id.jpg';
import tsaiIdImg from '../../images/common/tsai-id.jpg';
import jiaIdImg from '../../images/common/jia-id.jpg';

class ArtistSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistId: props.id,
      workId: props.workId,
      active: props.id
    }
    this.handleClick = this.handleClick.bind(this);
    this.moreClick = this.moreClick.bind(this);
  }

  handleClick(childCurrentId, childWorkId) {
    this.setState({
      artistId: childCurrentId,
      active: childCurrentId,
      workId: childWorkId
    });
    this.props.click(childCurrentId)
  }

  moreClick(artistId) {
    this.props.moreClick(artistId)
  }

  render() {
    const { artistId, active } = this.state;

    return (
      <div className="artist-section">
        <div className="artist-section__left">
          <ArtistCard
            id="Tsai-Ping-Ju"
            backgroundImage={curatorIdImg}
            borderColor="#FF645A"
            artistEn="TSAI PING-JU"
            artistTW="蔡秉儒"
            title="策展人"
            click={this.handleClick}
            active={active}
          />
          <ArtistCard
            id="Huang-Po-Chih"
            backgroundImage={huangIdImg}
            borderColor="#15FFC1"
            artistEn="HUANG PO-CHIH"
            artistTW="黃博志"
            title="藝術家"
            click={this.handleClick}
            active={active}
          />
          <ArtistCard
            id="Tsai-Wan-Chieh"
            backgroundImage={tsaiIdImg}
            borderColor="#15FFC1"
            artistEn="TSAI WAN-CHIEH"
            artistTW="蔡宛潔"
            title="藝術家"
            click={this.handleClick}
            active={active}
          />
          <ArtistCard
            id="Chia-Chieh-Ju"
            backgroundImage={jiaIdImg}
            borderColor="#15FFC1"
            artistEn="CHIA CHIEN-JU"
            artistTW="賈茜茹"
            title="藝術家"
            click={this.handleClick}
            active={active}
          />
        </div>
        <div className="artist-section__right">
          <ArtistIntro 
            artistId={artistId} 
            moreClick={this.moreClick}
          />
        </div>
      </div>
    );
  }
}

export default ArtistSection;
