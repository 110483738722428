import React, { Component } from 'react';

import '../../scss/index/about.scss';

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setHeight: false
		}
		this.openSection = this.openSection.bind(this);
	}

	openSection() {
    let section = document.getElementById('about-accordion-section');
    if (this.state.setHeight) {
      section.style.maxHeight = '480px'
      section.classList.remove('active')
    } else {
      section.style.maxHeight = '100%';
      section.classList.add('active')
    }
    this.setState({setHeight: !this.state.setHeight})
	}
	render() {
		return (
			<div className="about">
					<div className="about__left">
						<div className="about__left__text custom-scroll scroll-light" id="about-accordion-section">
							<div className="about__left__text__title">
									<h3>關於本計畫</h3>
									<div id="about-accordion" className="accor-btn" onClick={this.openSection}>
											<span className="cross"></span>
									</div>
							</div>
							<p>為符合資訊時代發展脈動與回應藝術環境需求的「台灣當代藝術資料庫」（Taiwan Contemporary Art Archive, TCAA），以一個雲端平台成為台灣當代藝術的脈絡梳理與自我展現的最佳工具與載體，由社團法人台灣視覺藝術協會（The Association of the Visual Art in Taiwan，AVAT）自2013年7月籌畫建置，2014年2月19日正式上線啟動，2016年7月全新網站改版至今，所規劃的項目和蒐錄資料已逐漸涵蓋台灣當代藝術類型發展，並定位為免費提供公眾使用的線上整合型平台，進行雲端國際連結，向國際藝壇即時且具體地呈現台灣藝術的當代性發展，有助於學術研究發展和策展實務。</p>
							<p>自2017年起，「台灣當代藝術資料庫」為推動對雲端藝術資料庫的應用，鼓勵對當代藝術策展具獨特想像的策展人，透過對藝術資料庫檔案的重新思考與運用，進一步從線上展覽的平台上生成新的策展可能性，因而規劃「台灣當代藝術資料庫線上策展徵件計畫」對外徵求全新策展提案，期望經由線上平台的介面形式，促進策展人與媒體技術人員或網站工程人員之交流與合作，拓展藝術對話與交流。</p>
							<p>而今年度，為持續推展線上資料平台之實際應用可能，結合視盟「2018藝術全青年學校」計畫，培育青年策展人並提供發表平台，延續去年度之計畫並經公告徵求線上策展提案後，評選出兩組策展案實踐，以期此線上展出形式得以不斷試驗、激盪，在數位時代中開拓屬於這個世代的觀看可能。</p>
							<p>
								<a href="http://tcaaarchive.org/" target="_blank" rel="noopener noreferrer">tcaaarchive.org</a>
							</p>
							<p>
								<b>主辦單位</b> 社團法人台灣視覺藝術協會<br />
								<b>指導單位</b> 文化部
							</p>
							<br />
							<p className="en">In response to the trend of art and the needs of art creators, The Association of the Visual Art in Taiwan (AVAT) established a cloud database Taiwan Contemporary Art Archive (TCAA). The preparation of TCAA began in July 2013, launched in Feb 19, 2014, and in July, 2016, brand new website update. TCAA is a free online platform for the public with sections covering the growing genres of Taiwan's contemporary art. It is an integral database connecting local artists to the international art society as well as the academic world. From researchers to curators, any user of TCAA will be informed with the development of Taiwan's contemporary art.</p>
							<p className="en">Since 2017, the TCAA, in an effort to advocate the usage of a cloud archive of artists, has encouraged curators with unique visions for curating contemporary art to utilize the online platform and expand the methods and possibilities of curation through the rethinking and implementation of the artist archive. Thus, with the Taiwan Contemporary Art Archive Online Curatorial Project Call for Proposals, we seek novel curatorial proposals. We hope to facilitate collaborations and networking between curators, new media technicians, and web engineers through an online interface, and simultaneously expand the dialogue and exchange of art.</p>
							<p className="en">This year, to further promote the pragmatic possibilities of the online archive platform, we combined with AVAT’s 2018 Arts School for Youth program to continue nurturing young curators and providing exhibition platforms. By extending the programs last year and calling for online curatorial proposals, then selecting two to carry out, we hope that this online exhibition format can be constantly tested and experimented with, opening up visual possibilities that belong to this digital age.</p>
							<p>
								<a href="http://tcaaarchive.org/" target="_blank" rel="noopener noreferrer">tcaaarchive.org</a>
							</p>
							<p>
								<b>Organizer</b> Association of the Visual Arts in Taiwan, Taiwan Contemporary Art Archive<br />
								<b>Advisor</b> Ministry of Culture, ROC
							</p>
						</div>
						<div id="rotate-text">ABOUT THE PROJECT.</div>
					</div>
					<div className="about__right">
							<div className="about__right__member">
									<h3>團隊成員</h3>
									<ul>
											<li><span>策展人：</span>蔡秉儒</li>
											<li><span>藝術家：</span>黃博志、蔡宛潔、賈茜茹</li>
											<li><span>主視覺設計：</span>魏妏如、陳彥如</li>
											<li><span>網站設計：</span>潘思翰</li>
											<li><span>網站工程：</span>陳逸雅</li>
											<li><span>特別感謝：</span>張靖瑜</li>
									</ul>
									<ul>
											<li><span>Curator</span> TSAI Ping Ju</li>
											<li><span>Artists</span> HUANG Po Chi, TSAI Wan Chieh, CHIA Chien Ju</li>
											<li><span>Graphic Design</span> CHEN Yen Ru,  WEI Wen Ru</li>
											<li><span>Website Design</span> PAN Shih Han</li>
											<li><span>Website Engineer</span> CHEN Yi Ya</li>
											<li><span>Special Thanks</span> CHANG Ching Yu</li>
									</ul>
							</div>
							<div className="about__right__email">
									<a href="mailto:pluto0110321@gmail.com" className="about__right__email__link">
											<div className="about__right__email__link__img"></div>
											<div className="about__right__email__link__arrow"></div>
									</a>
							</div>
					</div>
			</div>
		);
	}
}
  
export default About;
