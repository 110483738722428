import React, { Component } from 'react';

import '../../scss/common/modal.scss';

import ModalHeader from  '../common/modalHeader'
import SubNav from  '../common/subNav'
import Marquee from  '../common/marquee'
import ArtistSection from  '../artistSection/index'
import WorkSection from  '../workSection/index'
import Footer from '../footer'

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: props.id,
      isArtistPage: (props.artistId) && true,
      currentId: (props.artistId) ? props.artistId : props.workId
    }
    this.handleClick = this.handleClick.bind(this);
    this.moreClick = this.moreClick.bind(this);
  }

  handleClick(childId) {
    this.setState({
      modalId: 'artist-modal',
      isArtistPage: 
        !(childId === '500-Lemon-Trees' || 
        childId === "The-White-Dew-Turns-into-Hoarfrost" || 
        childId === "My-City"),
      currentId: childId
    });
  }

  moreClick(artistId) {
    if (artistId === 'Huang-Po-Chih') {
      this.setState({
        modalId: 'work-modal',
        isArtistPage: false,
        currentId: '500-Lemon-Trees'
      });
    } else if(artistId === 'Tsai-Wan-Chieh') {
      this.setState({
        modalId: 'work-modal',
        isArtistPage: false,
        currentId: 'The-White-Dew-Turns-into-Hoarfrost'
      });
    } else {
      this.setState({
        modalId: 'work-modal',
        isArtistPage: false,
        currentId: 'My-City'
      });
    }
  }

  render() {
    const { modalId, isArtistPage, currentId } = this.state 
    const folder = isArtistPage ? "Curator&Artist" : "Artwork"
    const marqueeText = isArtistPage ? "Curator and Artist" : currentId.replace(/-/g, ' ')

    return (
      <div className="modal-section" id={modalId}>
        <ModalHeader 
          folder={folder}
          file={currentId}
          handleClose={this.props.handleClose}
        />
        {!isArtistPage &&  
          <SubNav 
            id={currentId}
            click={this.handleClick} 
          />
        }
        <Marquee 
          id="main-marquee"
          text={marqueeText} 
        />
        {isArtistPage ?
          <ArtistSection 
            id={currentId}
            click={this.handleClick} 
            moreClick={this.moreClick}
          /> : 
          <WorkSection 
            id={currentId}
            click={this.handleClick} 
          />
        }
        <Footer />
      </div>
    );
  }
}

export default Modal;
