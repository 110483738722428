import React, { Component, Fragment } from 'react';

import '../../scss/index/artistrow.scss';

import ArtistCard from  '../common/artistCard'
import Modal from  '../common/modal'

import curatorIdImg from '../../images/common/curator-id.jpg';
import huangIdImg from '../../images/common/huang-id.jpg';
import tsaiIdImg from '../../images/common/tsai-id.jpg';
import jiaIdImg from '../../images/common/jia-id.jpg';

class ArtistRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionOpen: false,
      artistId: ''
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(childId) {
    this.setState({
      sectionOpen: true,
      artistId: childId
    });
    document.body.classList.add('modal-open');
  }

  handleClose() {
    this.setState({
      sectionOpen: false
    });
    document.body.classList.remove('modal-open');
  }

  render() {
    const { sectionOpen } = this.state

    return (
      <Fragment>
        <div className="artist-row">
          <ArtistCard
            id="Tsai-Ping-Ju"
            backgroundImage={curatorIdImg}
            borderColor="#FF645A"
            artistEn="TSAI PING-JU"
            artistTW="蔡秉儒"
            title="策展人"
            click={this.handleClick}
          />
          <ArtistCard
            id="Huang-Po-Chih"
            backgroundImage={huangIdImg}
            borderColor="#15FFC1"
            artistEn="HUANG PO-CHIH"
            artistTW="黃博志"
            title="藝術家"
            click={this.handleClick}
          />
          <ArtistCard
            id="Tsai-Wan-Chieh"
            backgroundImage={tsaiIdImg}
            borderColor="#15FFC1"
            artistEn="TSAI WAN-CHIEH"
            artistTW="蔡宛潔"
            title="藝術家"
            click={this.handleClick}
          />
          <ArtistCard
            id="Chia-Chieh-Ju"
            backgroundImage={jiaIdImg}
            borderColor="#15FFC1"
            artistEn="CHIA CHIEN-JU"
            artistTW="賈茜茹"
            title="藝術家"
            click={this.handleClick}
          />
        </div>
          {
            sectionOpen &&
              <Modal 
                id="artist-modal"
                handleClose={this.handleClose}
                artistId={this.state.artistId}
              />
          }
      </Fragment>
    );
  }
}
  
export default ArtistRow;

