import React, { Component } from 'react';

import '../../scss/work-section/index.scss';

class WorkSectionMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setHeight: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.openSection = this.openSection.bind(this);
  }

  handleClick() {
    this.props.click(this.props.id)
    document.getElementsByClassName('modal-section')[0].scrollTop = 0;
  }

  openSection() {
    let section = document.getElementById('work-accordion-section');
    if (this.state.setHeight) {
      section.style.maxHeight = '480px'
      section.classList.remove('active')
    } else {
      section.style.maxHeight = '100%';
      section.classList.add('active')
    }
    this.setState({setHeight: !this.state.setHeight})
  }

  render () {
    const { backgroundImage, artistEn, artistTw, workIntro } = this.props;

    return (
      <div className="work-section__main">
          <div className="work-section__main--top">
            {this.props.children}
          </div>
          <div className="work-section__main--bottom">
              <div id="work-accordion-section" className="work-intro custom-scroll scroll-light">
                <div className="work-intro__title">
                  <h3>作品介紹</h3>
                  <div id="work-accordion" className="accor-btn" onClick={this.openSection}>
                    <span className="cross"></span>
                  </div>
                </div>
                <div 
                    className="work-intro__text"
                    dangerouslySetInnerHTML={{ __html: workIntro}}
                >
                </div>
              </div>
              <div className="artist">
                  <div className="artist__top">
                      <div className="artist__top__name">
                          <h3>藝術家</h3>．<h3>{ artistTw }</h3>
                      </div>
                      <div 
                          className="artist__top__img"
                          style={{ backgroundImage: `url(${backgroundImage})` }}
                      >
                          <h3>{artistEn}</h3>
                      </div>
                  </div>
                  <div className="artist__more" onClick={this.handleClick}>
                    <div className="artist__more__link">
                        <h3>Watch More</h3>
                    </div>
                    <div className="artist__more__arrow"></div>
                  </div> 
              </div>
          </div>
      </div>
    );
  }
}

export default WorkSectionMain;
