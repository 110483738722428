import React, { Component, Fragment } from 'react';

import huangBk from '../../../images/index/works-huang.jpg';
import tsaiBk from '../../../images/index/works-tsai.jpg';
import jiaBk from '../../../images/index/works-jia.jpg';

import Work from  './work'
import Modal from  '../../common/modal'

class Works extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionOpen: false,
      workId: ''
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(childId) {
    this.setState({
      sectionOpen: true,
      workId: childId
    });
    document.body.classList.add('modal-open');
  }

  handleClose() {
    this.setState({
      sectionOpen: false
    });
    document.body.classList.remove('modal-open');
  }

  render() {
    const { sectionOpen } = this.state
    
    return (
      <Fragment>
        <div className="works">
          <Work 
            id="500-Lemon-Trees"
            backgroundImage={huangBk}
            linkColor="#FBF138"
            titleEn="500 LEMON TREES"
            titleTw="五百棵檸檬樹"
            artist="黃博志"
            click={this.handleClick}
          />
          <Work
            id="The-White-Dew-Turns-into-Hoarfrost"
            backgroundImage={tsaiBk}
            linkColor="#15FFC1"
            titleEn="THE WHITE DEW TURNS<br>INTO HOARFROST"
            titleTw="白露為霜"
            artist="蔡宛潔"
            click={this.handleClick}
          />
          <Work 
            id="My-City"
            backgroundImage={jiaBk}
            linkColor="#FF645A"
            titleEn="MY CITY"
            titleTw="我的城市"
            artist="賈茜茹"
            click={this.handleClick}
          />
        </div>
        {
          sectionOpen &&
            <Modal 
              id="work-modal"
              handleClose={this.handleClose}
              workId={this.state.workId}
            />
        }
      </Fragment>
    );
  }
}

export default Works;
