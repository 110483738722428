import React, { Component } from 'react';

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setHeight: false
    }
    this.openSection = this.openSection.bind(this);
  }

  openSection() {
    let section = document.getElementById('accordion-section');
    if (this.state.setHeight) {
      section.style.maxHeight = '480px'
      section.classList.remove('active')
    } else {
      section.style.maxHeight = '100%';
      section.classList.add('active')
    }
    this.setState({setHeight: !this.state.setHeight})
  }

  render() {
    return (
      <div className="intro">
        <div id="accordion-section" className="intro__text custom-scroll scroll-dark">
          <div className="intro__text__title">
            <h3>
             Curatorial Statement.
            </h3>
            <div id="accordion" className="accor-btn" onClick={this.openSection}>
              <span className="cross"></span>
            </div>
          </div>
          <p>延續展覽〈無何有鄉〉，我試圖聚焦於離開與迴返這段移置經驗中所帶來的過渡性體驗，在遞嬗於藝術創作的同時，也證呈了一個世代的觀點。如果說「遷移」與「離散」的路向是海島作為移民社會的文化樣態，我們如何面對故土中受各方拉扯的拮抗力道？及其積累對於文化認識上的某些斷裂，這個裂面在指向了他方的生活時，同時也指向了島嶼不斷試圖拼湊的過去。而在回歸地方的同時，重新詮釋屬於當下的政治現實敘事，則需借助對於過往的翻掘與理解。</p>
          <p>也因此，此次展覽的作品皆與地方有所重構，並在連結兩者時探尋各方延伸對話的可能。藝術家以「行動」觸及作為「地方」所指的文本時，亦牽動了情感或經驗上的認同，此時作為作品本身一環的「行動」便形同再次理解土地的過程，應運而生的是重新撰述與現下敘事相應的語法，且在回應因創作的行動過程而逐被突顯的原鄉精神時，亦為譯註島嶼背後積藴的漫長宿命。而背面所為對照的，不單只是回應某種歷史般的書寫姿態，更是更為深切的政治認同。屆時，經過重新翻考後的「所指」，將是一個得以凝望與對話的地方。</p>
          <p>《五百顆檸檬樹》中關於藝術家返鄉種植的部分正是對應到了上述關鍵，亦是此次展出的切入所在。然而相較於作品計畫主軸的眾籌合作，其實我更關注於出版物中的散文篇章，描述了藝術家在菲律賓服役的生活經過、以及與當地朋友間的互動，補足了此計畫在固有展呈上的整體脈絡。而這一段「緣起」的故事，關於異地生活間，隱約透露了一種對於原鄉對照，並在時序銜回島嶼後，藝術家重新將家族荒廢的田地整耕，將自己的理想植於土壤。</p>
          <p>蔡宛潔的作品《白露為霜》以閱讀台灣早期劇場導演田啟元的劇本《毛屍》，考察以往演出時所用之場景、服裝與肢體動作等，將文字轉化為具有強烈視覺感的影像，以身體、當代的影像語彙詮釋八零年代對抗政治結構的文體，配以藝術家延伸創作的旁白文句，映照出當前社會在特定議題上面臨的巨大分歧。</p>
          <p>賈茜茹的《我的城市》則是過往在其他國家駐村時，捕捉路邊機遇的街景，而這些切片般的城市景象，對於藝術家來說總有虛晃一陣的錯覺，似是步行台北街頭時熟悉的光景。不經讓人思考地方與經驗的關聯性，於是藝術家刻意打散來自各城市拍攝的照片後，再混入台北拍攝的風景照，並重新上傳至社群網路平台。在有意無意的參照下，此時已難辨別影像所指的場所為何方。</p>
          <p>指南並非一種必經途徑，而是在力行某些作為時的借助提案。我們尚可視此三件作品為某種參照可能，「翻掘指南」成為重新理解前述論述標的的一種方法。而「閱讀」作為理解指南之舉，一方面展出作品在理解上需以文字內容作為呈現的一部分；另一方面，藝術家們在實踐創作的同時，也觸及了再理解過往事物的經驗，如同閱讀一般，在指認文字的同時，也反饋我們想像的樣貌。一如蔡宛潔在重新詮釋《毛屍》時，書寫了「故事」與劇本本身的文字對話。而《我的城市》則在拼接地域經驗的過程中，如小說虛實共構般的重新杜撰了一段遊歷體驗。《五百顆檸檬樹》行動過程中的身體，則回應了黃博志透過勞動重新翻掘家鄉的田地。</p>
          <p>再認識並重新詮釋地方的故事，這段歷程作為此次線上展出之主軸，如同指南手冊般提供了我們一些理解土地的行動想像。並在目光放諸前方的同時，讓我們得以有個駐足並稍稍喘息的機會。</p>
        </div>
        <div className="intro__banner border"></div>
      </div>
    );
  }
}

export default Intro;
