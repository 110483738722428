import React from 'react';

const Footer = () => {
  return (
    <footer>
      <h2>
        <span>©</span> 
        <span>Taiwan</span>
        <span>Contemporary</span>
        <span>Art</span>
        <span>Archive</span>
        <span>2018.</span>
        <span>DON'T</span>
        <span>STEAL,</span>
        <span>SHARE!</span>
      </h2>
    </footer>
  );
}

export default Footer;
