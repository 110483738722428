import React from 'react';

import WorkTextTsai from '../../images/work-text-tsai.png';

const TsaiWork = () => {
  return (
    <div className='tsai-work'>
      <div className='tsai-work__title'>
        <img src={WorkTextTsai} alt="白露為霜" />
      </div>
      <div className='tsai-work__video'>
        <iframe 
          title="白露為霜"
          width="560" 
          height="315" 
          src="https://www.youtube-nocookie.com/embed/qVVDv3OquSc" 
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>        
      </div>
    </div>
  );
}

export default TsaiWork;