import React, { Component, Fragment }  from 'react';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'

import 'react-id-swiper/src/styles/css/swiper.css';

import WorkTextHuang from '../../images/work-text-huang.png';

const menuList = [
  {
    id: 1,
    name: '牽牛花',
    content: `
      <h5>牽牛花</h5>
      <p>綠洲合唱團的《(What's the Story) Morning Glory》是我購買的第一張CD，至今依然在我的汽車音響播放清單中，清晨開車返回檸檬園時我會大聲播放。牽牛花是Morning Glory，是田邊隨手可得的窮人致幻劑，是英國俚語中的晨勃，交雜出的巧合僅僅足夠讓你想起所有夢想，但它永遠不會來得太早，因為明日永遠不知曉。</p>
      <br>
      <p>
        Need a little time to wake up<br>
        Need a little time to wake up wake up<br>
        Need a little time to wake up<br>
        Need a little time to rest your mind<br>
        You know you should so I guess you might as well<br>
        What's the story morning glory?<br>
        Well<br>
        (you) need a little time to wake up wake up
      </p>
      <br>
      <p>
        要一點時間去清醒<br>
        給我點時間來好好清醒<br>
        要一點時間去清醒<br>
        需要點時間去整理思維<br>
        你知道這是應該的，我猜你也這樣認為<br>
        什麼好夢，睡得如何？<br>
        嗯<br>
        你需要點時間好好清醒一下
      </p>
      <br>
      <p>我對早早起身返回檸檬田的自己略感驕傲，即使我還是睡眼惺忪。坐上駕駛座，啟動、點火，再來輕觸雨刷鍵，「摳空！摳空！」老舊的雨刷發出話語般的聲響，除去累積了一夜的灰塵。在這些動作之後，我打開車內的音響。</p>
      <br>
      <p>
        All your dreams are made<br>
        When you're chained to the mirror on the razor blade<br>
        Today's the day that all the world will see<br>
        Another sunny afternoon<br>
        Walking to the sound of your favorite tune<br>
        Tomorrow never knows what it doesn't know too soon<br>
      </p>
      <p>聽完綠洲合唱團的《(What's the Story) Morning Glory》一整張專輯中所有歌曲，與我從台北萬華開車回到鄉下老家檸檬園所需的時間差不多。有時候我只重複播放整張專輯裡我最喜歡的一首歌，重播十次之後，離桃園中壢不遠了，那再五次之後呢？我知道下一首歌結束前，就要抵達觀音老家。</p>
      <br>
      <p>在通往檸檬園的產業道路上，我常常不時偷瞄廢耕田地邊上架起的鐵絲網圍籬，因為向陽的一端爬滿了紫色牽牛花，或許是過於普遍，一直以來，我從不認為牽牛花有任何美麗之處。日本人稱牽牛花為「朝顏」，只在清晨綻放，傍晚凋謝。我喜歡稱牽牛花「日頭花」，經驗老練的農人可以透過觀察陽光直射的角度和牽牛花綻放或閉合的程度來精準推算時間。我嘗試了幾次，雖然誤差有時，但我相信，如果生理狀態接近植物的生長週期時，或許可以理解植物多一些。</p>
      <p>牽牛花也是我對於小學生活僅存的記憶之一。還在讀小學時，每年夏天，我們像蜜蜂一樣爭奪牽牛花花蜜。我與同學們從校園四周鐵絲網圍籬上摘下各式各樣的牽牛花，這是某位熱心公益的校友所捐贈的植栽牆，有來自世界各地不同品種的牽牛花攀附在鐵絲網上。我們剔除花托、花萼，從花瓣末端輕輕一吸。運氣好時，可以嘗到清甜的花蜜；運氣不好時，花粉從喉嚨偷溜進氣管直衝鼻腔，噴嚏與鼻涕、咳嗽與唾液接連而來，擾亂情緒。</p>
      <p>「禁摘牽牛花教務處」</p>
      <p>學校明文禁止學生採摘牽牛花。</p>
      <p>家住隔壁，與我年紀有很大差異的阿丁，他的叛逆與憂鬱讓我們敬畏又羨妒。他板著臉指示我們摘下十幾朵花，他雙掌一併，命令我們將花放進他的掌中。接下所有牽牛花後，他雙手合十，輕輕搓揉，再將雙手湊近鼻子，連續好幾個慢條斯理的深呼吸。他吸食牽牛花散發出的氣味，雖然表情嚴肅，但肩膀顯得格外輕盈，他整個人隨著吸氣而升，吐氣緩緩而降，就像快要飄起來一樣。緊接著，他點燃一根菸，又陷入他一貫的沉默。他的沉默像突然刮起的風，帶走他所有的情緒，而我討厭這種時候，因為讓我不知所措。</p>
      <p>我傻傻地吹著風。</p>
      <p>沉默過後，他再次使喚我們：「去幫我買兩罐養樂多。」阿丁很有趣，不管喝什麼東西，都會加上養樂多調味，他說這是從朋友那學來的。他害怕啤酒的苦味，他加上養樂多；他害怕茶的澀味，他加上養樂多；他害怕沒有味道的白開水，他加上養樂多，還有嚼檳榔時，他也配上養樂多和運動飲料｜舒跑。接近秋天，阿丁會用石頭敲碎牽牛花種子，放進養樂多中一口喝下，我看著他情緒緊繃的臉上漸漸多了白日夢中的柔和線條。我與同學們有樣學樣，偷偷收集了一些牽牛花種子，不同之處在於我們是將敲碎的種子撒在有花生顆粒的巧克力條之上，因為我們並不那麼喜歡養樂多。</p>
      <p>我們將磨碎的種子塞進巧克力條中，因為害怕嘗到牽牛花種子的味道，我們急忙吞下巧克力條。</p>
      <br>
      <p>這是畢業典禮前最後一個上課日。</p>
      <p>漸漸的，我們的天空不再是一片蔚藍，是迷人的、淡淡的粉紫色；身體不再是蛋白質的組合，是一種經過高溫烘焙後層層疊疊的千層酥皮，很像台式經典麵包—起酥皮肉鬆麵包。我們知道這層層酥皮之中藏著一隻白粉蝶，是給我們自己的某種訊號。它浮躁，振翅待飛，而它每次揮動翅膀，焦脆的酥皮便紛紛剝離。「一秒、兩秒、三秒……」有一位同學振振有詞地計算白粉蝶破繭而出的時間。另一位失去了時間感的同學，不停地在六秒與七秒之間來來回回重複碎唸著。我在我所認為的第九秒時，目光緊盯白粉蝶，轉動我發育不全的肩膀—上臂—手肘—下臂—手腕到每隻手指頭，學著眼前四處亂竄，胡亂飛舞的白粉蝶，學著飛舞在粉紫色的天空裡，在我凌亂的意識之下。</p>
      <p>我們發了瘋似的吼叫與班導師的咒罵混雜在一起，像沒有節奏感的音樂。我覺得自己脫口而出的每字每句，都各自有不同的顏色，紅、橙、黃、綠、藍、靛、紫。在某一瞬間，不知道是聲音本身失去了聲音，還是我失去了聆聽聲音的能力。我用沒有邏輯的問題不停逼問他，但我聽不見他的回應。他是一位備受眾人歡迎和矚目的同學。我惱怒、我嫉妒，我嫉妒他所擁有的、我羨慕他所展現的，我認為是他讓我顯得匱乏。</p>
      <p>我們一群三人將他拉到了長滿牽牛花的牆邊。日正當午，陽光像箭，陣陣刺痛，抵著臉、抵著背。因為陽光，我們張不開眼，只是一股腦地接連揮出鬧著玩的拳頭，落在他的前胸、後背和臉上。一拳拳鬧著玩的拳頭，拳頭中握著的是藏不住的惡意。</p>
      <p>拳頭落在他身上後，疼痛、興奮、愧疚像快速生長的攀藤植物在我體內蔓延，並且不間斷地「嗶嗶剝剝」響。</p>
      <p>牽牛花、他的名字、拳頭落在他身上的感覺、褪不去的愧疚感，除此之外，小學時代的過去，鮮少回憶，都深深沉沒在我腦海深處。以前爺爺總是說我的手很大，不是握拳就是握鋤頭。</p>
      <p>這麼多年後，我並沒有緊握雙拳，而是手握鋤頭。</p>
      <p>「無名無姓，問钁頭柄。」客家話中的傳統俚語，諷喻沒有頭緒之人，如何成就大事？</p>
      <br>
      <p>至今，依舊理不出頭緒。我選擇種下檸檬樹，種下五百棵檸檬樹，問問鋤頭，我會是誰。</p>
    `
  },
  {
    id: 2,
    name: '哈囉．哈囉',
    content: `
      <h5>哈囉．哈囉</h5>
      <p>二〇一二到二〇一三年間，我在菲律賓服兵役，正確來說應該是教育替代役，進駐菲律賓華校，用我癟腳的英文教授中文課程。除了執行替代役男所應盡的義務外，空閒的時間，我常常漫無目的地穿越一個又一個依海岸線而建的村落。我有時穿著夾腳拖緩慢步行，有時換上跑步鞋全力衝刺。通常我會用右手緊抓單眼相機，雖然我鮮少按下快門。手中的相機讓我不時會重心不穩，特別是跑步的時候，重心頻頻偏向右邊，遇到岔路時，也就順勢往右邊轉過去了。</p>
      <p>過去也有類似帶著相機跑步的經驗。我還在台灣，正等著入伍服役的通知書時，工作、課業都暫時停擺，這段空窗期我常常慢跑，沒有任何目的地，每次跑步我右手都會手拿單眼相機，總想拍些東西，通常的結果是連一次快門都沒按下，倒是跑著跑著，因為重心偏向手拿相機的右手，最後竟然不自覺地養成了習慣性右轉，算是壞習慣吧。我並不排斥這個壞習慣，也沒有想要矯正這壞習慣，最後我索性將習慣性右轉做為一種遊戲規則，我跑步時的規則。其中有一次讓我印象特別深刻，因為我碰到了一位在台灣打工的菲律賓男孩，他開啟了我對菲律賓的種種想像，也影響了我服役地點的選擇，我選擇去了他的家鄉，想親眼看看他所說的一景一物。</p>
      <p>「我去跑步。」我對著擋在門邊慵懶的貓說。</p>
      <p>「喵。」貓精神渙散地回應了我一聲。</p>
      <p>我一邊開門一邊暗自再三督促我自己，當我想要轉彎，只能選擇右轉。我從租屋處出發，第一個右轉，是間高職女校，剛好碰上一隊一隊放學回家的女學生們，個個身著粉紅色的上衣，黑色百褶短裙。她們的笑聲不斷，像浪一波波襲來，侵略短促巷內中的每個角落。</p>
      <p>窄巷內人滿為患，讓我略感不安與焦躁，我向右轉入小巷，避開人潮。</p>
      <p>小巷僅一台車身寬。巷內是半毀的日式宿舍群與小眷村混雜而成的聚落，其中藏匿了一間間不開門、不具名的私娼寮。我穿梭其中。我喜歡穿越這個區域，在這不起眼的區域之中，人口結構的變遷，足以見證並且反映當地勞動力的變化歷程。最早是被迫遷滯台的退役老兵與假結婚來台打工的中國籍婦女，相同的方言、鄉音，是最直接的安慰。幾年過去，不時瞥見精力過剩的本地勞、農工與假日兼職的外籍配偶在打情罵俏，彼此之間不同語言的誤解和錯譯，自然而然成為不需任何修飾的笑點。接著是來自東南亞各國的外籍移工們，他們用我們熟悉的語言交流著，口音濃厚的國語是他們之間最大的交集。只是不管是誰，看見我手中的相機，都多了一份戒心，即使我只是匆匆跑過。</p>
      <p>我在圍牆轉角種了一棵大榕樹的民宅前右轉，朝著火車站方向而去。</p>
      <p>我在火車站前的圓環繞了好幾圈，大約五十坪大小的公園，每個能夠利用的空間都擠滿了來自東南亞各國的移工，或站或蹲或坐，他們吃飯、喝酒、唱歌、跳舞、聊天。我看得入迷。陽光穿透榕樹樹梢，枝與葉讓陽光成為跳舞的影子，在跳舞的人身上跳著，在唱歌的人身上數著節拍。這讓我想起一首很喜歡的歌，我停了下來，將播放中的音樂切換至林生祥與交工樂隊的，〈種樹〉。</p>
      <p>
        種給離鄉的人<br>
        種給太寬的路面<br>
        種給歸不得的心情<br>
        種給留鄉的人<br>
      </p>
      <p>
        種給落難的童年<br>
        種給出不去的心情<br>
        種給蟲兒逃命<br>
        種給鳥兒過夜<br>
      </p>
      <p>
        種給太陽生影跳舞<br>
        種給河流乘涼<br>
        種給雨水停歇<br>
        種給南風吹來唱山歌<br>
      </p>
      <p>基於安全考量，執勤員警示意與車爭道的我應該離開車道。我該逃命了！我特別右轉轉進火車站前的一條巷子內。</p>
      <p>這條巷子中林立了許多華裔菲律賓人所經營的超市、餐廳、小吃店。我放慢腳步，右轉走進一間超市中。沒過多久，有人輕拍我的左肩。是一位與我年紀相仿的菲律賓男孩，他眼神充滿好奇心，觀察我頭上一條條的髒辮，用他不流利的中文加上比手畫腳盯著我問，「你的頭髮哪裡弄的？」我以左手食指指向自己做為回答。他拉起自己的長捲髮，說著自己的雷鬼音樂夢，我們互相留下了聯絡電話並道別。而我並沒有繼續我的慢跑遊戲，我走出超市，往左邊轉去，坐在一間小吃店的戶外座椅上，點了一碗菲律賓男孩推薦的甜點「Halo Halo」。他說「Halo Halo」是菲律賓傳統的街頭小吃，與台灣的傳統刨冰很類似，最大的差異應該是「Halo Halo」多了椰奶和色彩繽紛的人工色素椰果。自此之後我都叫他「Halo Halo」。</p>
      <p>他從沙烏地阿拉伯到美利堅合眾國，歷經十年的移工生活。被時差困擾的他，最後選擇落腳台灣，因為菲律賓與台灣，隸屬於同個時區的上下兩端。我問他：「Halo，你還會回到菲律賓嗎？」他什麼都沒有說，這是他少有的沉默時刻。事實上，每次與他見面，他總是滔滔不絕，我則是相對沉默。</p>
      <p>坦白說，我很羨慕他所擁有過的生命經驗，以及自在分享時的愉悅神情，不論是工作上或單單只是生活上的瑣碎庶務。更多時候，他會不停地驕傲說著他的家鄉，說著他無法忘懷的一景一物，說著炎熱的天氣，說著突如其來的午後雷陣雨，說著街上傳來的陣陣氣味，說著從港邊魚市場飄來的魚腥味。說著極端的貧富差距，說著持續惡化的勞動條件。說著他即將逃離，逃離勞力仲介公司的變相剝削。</p>
      <p>還有，說著粉紅色椰子酒帶給他許多粉紅色的夢，請我務必要嘗嘗。</p>
      <p>我說我要入伍服役了，我會選擇去你的家鄉看看。</p>
      <p>我們說了再見。</p>
      <p>入伍前，我撥了他的電話，已經轉為無人使用的空號。我想他已經成為一隻逃跑的蟲兒，正尋找著可以讓他盡情跳舞的樹梢。我則是想去看看，他口中那個影子會一起快樂起舞的美麗故鄉，還有照耀七千島嶼的燦爛天光。</p>
    `
  },
  {
    id: 3,
    name: '椰子紅酒',
    content: `
      <h5>椰子紅酒</h5>
      <p>我第一次喝到〈Estambay(Street Corner Guy)〉這首歌裡提到的椰子酒，是在海濱公園附近堤防邊上的小攤販。一座以檳榔樹幹為骨幹，再以回收木材和漂流木拼裝而成的木造方型結構物。面對海的那一側牆上，有八塊塗滿淡藍色水泥漆的木板，共三公尺寬，一公尺半高，每塊木板上各寫了一個大寫英文字母，T、H、E、B、E、A、C、H，是很隨興的手寫字體，店內貼了一張李奧納多．狄卡皮歐（Leonardo Wilhelm DiCaprio）在二〇〇〇年所主演的《海灘》（The Beach）電影海報。《海灘》是老闆最愛的電影，不免俗地，他也自稱「李奧」（Leo），並以「海灘」（The beach）為酒吧命名，並自詡為海濱堤防上最好的酒吧。其實整間酒吧只夠李奧一人容身，僅剩的空間都被一箱一箱疊至屋頂的塑膠收納箱給占據。他的口頭禪是「歡迎來到海灘！」，即使附近並沒有任何一粒沙。</p>
      <p>日出後，李奧愜意地將淡藍色的木板依序拆下，拆下後可以看見另一片藍，天空的藍，攪和著熱氣的藍。</p>
      <p>卸下木板後，李奧開始組裝吧台。吧台做工粗糙，卻有設計精細的木栓結構，我喜歡這樣矛盾的對比。有段時間，我常常拜訪李奧，我向他請教吧台製作的工法、結構等等，可惜至今我仍然不懂他是如何讓這可隨意拆卸組裝的結構如此穩固，足以讓三、四個爛醉又體重超標的三輪車司機或趴、或躺、或坐、或靠在其上，這對我而言一直是個解不開的謎。</p>
      <p>日落時，李奧收起遮住夏天的遮陽帆布，讓橙色的落日打在吧台桌上。他為他所擁有的每樣物件都命了名，並且親手簽上物件的命名和他自己的名字。例如遮陽帆布是「夏天」，水杯是「水」，每個名稱都暗指了物件和其相對應的功能性，也反映了他看待世界的方式。我隨口問了幽默的李奧：「收起夏天後，剩下的是什麼季節？」他機智地回答說：「冬天永遠不會來，只有雨天從天上掉下來。」他常常驕傲地說自己雖然什麼都沒有，但有足夠的幽默感，他說幽默感在菲律賓跟水和空氣一樣重要，是生命生存下去的必要元素之一。</p>
      <p>「我想起了一個藝術家的作品。」我說。我向李奧借了一支麥克筆並在玻璃杯上寫下「到了晚上這杯水將會變黑」（At night this water turns black）1。他接過水杯和筆，將我寫的句子塗改為「到了晚上這個男人將變有錢」（At night this water man turns black rich），放在自己胸前。我們都笑了，因為我們知道，目前擁有的，只有微笑是真實的。</p>
      <p>我還記得我第一次經過「海灘」時所聞到的氣味和景象。我遠遠望著李奧和他的三輪車司機朋友們，一種心態上的遠距離，實際上我聽得到他們談話的聲音。日常生活對他們來說就是表演，這是我極致的稱讚，因為我希望自己的生活足以有價值做為一種表演形式；他們正由衷地融入角色之中，一句句話是一幕幕的台詞，手中的酒杯和喝了一半的酒，是主導角色性格的劇本。他們的笑聲，讓不停拍打堤防的浪都顯得沉默。</p>
      <p>「我的朋友！」李奧突然高聲叫住正在拍攝日落的我，請我幫他們拍張合照。他們過度膨脹的情緒實在難以捕捉，我作勢按了好幾次快門（李奧請原諒我，我並沒有按下快門。），他口中揮散不去的酒氣有點強勢，有點逼人，氣味像腐敗的橘子，連同口中的溼氣卯足全力向我迎面襲來。他邀請我加入聚會，興奮地介紹他引以為豪的自釀紅酒和他的酒吧。</p>
      <p>我靠坐吧台桌面，桌面左上角寫了「水、可樂、椰子酒」（Water, Cola, Tuba），是這間酒吧提供的僅有選擇。桌面幾乎滿是水漬，一圈又一圈，共有三種大小，是水杯、可樂、椰子酒經年累月下所形成的水漬。「這是酒吧的年輪。」李奧指出年代最久遠的水漬。桌面是未經防腐、防水處理的木頭所組成，唾液、汗水、可樂、椰子酒一點一點地滲透進桌面的縫隙中，在陽光照射後開始發酵。氣味分了好幾個層次，最先讓人察覺的是糖類發酵後溫潤的甜膩滋味，淡淡花果香混合了樹木枝幹的苦澀，隨之是迎面而來帶著鹹味的海風，海風的鹹混合桌面散發出的甜，味道像廉價醃漬品。最後一個層次的味道，是來自桌面最上層的油垢，由人體分泌物累積而成（李奧特別強調是皮膚的油脂），形成一股怪異腥臭味，煮熟後才開始腐敗的蝦蟹類也有類似氣味，同樣讓人噁心頭暈。</p>
      <p>「紅酒？葡萄做的紅酒嗎？」</p>
      <p>「是掛在天上的葡萄！」李奧說完後便拿出一瓶裝滿粉紅色液體的玻璃瓶，足足有半加侖，玻璃瓶上寫著「粉紅色的夢，李奧」（Pink dream, Leo）。</p>
      <p>一行人慫恿我喝下滿滿一杯的酒，台灣九八無鉛汽油或是電鋸所使用的二行程機油也有著類似的粉紅色。</p>
      <p>「這是什麼做的？」我一口喝下，臉上滿是皺紋，心中滿是疑問。</p>
      <p>「Tuba、tuba、tuba 酒……」李奧帶著醉意指著海濱高聳的椰子樹，之後用力拍著自己的胸脯。「椰子酒？」我面帶苦笑，伸出手指，並示意我了解這是他自己釀造的椰子酒。</p>
      <p>「這是菲律賓風格的紅酒！椰子是掛在天空的大葡萄！」李奧相當驕傲，「Tagay na !」他又幫我盛滿一杯，並且向我乾杯致敬。</p>
      <p>我喝下肚的是當地傳統風格椰子酒，口味苦澀中帶甜，的確有類似葡萄皮中單寧酸的酸澀口感。不顧我是否有意願，李奧開始像表演般說起自己如何釀造椰子酒，鉅細靡遺。</p>
      <p>我只在我想做酒的時後做酒。我可以徒手爬上椰子樹，用藤做的繩子綁住椰子的花芽，讓花芽低頭朝下，我用刀削去老舊乾枯的花芽，椰子樹的枝液會開始滴下來，有嫩竹的香味，大家都說這是椰子蜜，或是生命之水，但我喜歡稱這種汁液是椰子樹的口水。我用竹筒懸掛在椰子花芽下面，接住椰子花不停流下的口水。竹筒盛滿後，就直接在竹筒內自然發酵。加上栲樹皮來幫助酵母發酵，之後我到家裡附近的潮間帶，採一些紅樹林的枝幹，增加風味和顏色，同樣也會幫助發酵，加入的量是關鍵，足以決定酒的風味，最後還有我的祕密草藥，讓你可以做夢的草藥。</p>
      <p>李奧拿出另一瓶半加侖的椰子酒，顏色較深，是漂亮的栗子色，還有日落般的紅。「這是Bahalina，陳釀椰子酒。」</p>
      <p>我想起了我在台灣認識的菲律賓男孩「Halo」，這就是他曾經提及給他許多粉紅色夢境的椰子酒。「Tagay na ! 」李奧不時斟滿酒杯。我們共享同一個酒杯，酒杯上滿是指紋。他再三重複說的「Tagay」，可以是乾杯、祝你健康、你隨意，是一種複合意義的問候語，只在喝椰子酒時具有意義；可以是共享、分享、獨享，是一種團體價值觀。我說：「有什麼不是屬於『Tagay』的嗎？」我沒有得到答案，雖然可以透過網路搜尋資料，正確翻譯這個單字的意義，但我知道，這不僅涉及字面上的理解，更包含字面外無法翻譯的抽象概念，在我將這些經驗化約為文字、故事的同時，也不免隱含了自己文化的世界觀。事實上，他們並沒有同時舉杯慶祝高喊「乾杯」的飲酒文化，因為通常只有一個杯子，輪流喝下不停被斟滿的酒。在獨自喝下滿滿一杯椰子酒前的一句「Tagay」，和一口喝下的瞬間，包含了共享經驗的概念，紀念的是彼此當下經驗正在發生，經驗的界線正在被互相跨越｜正在發生也正在結束。</p>
      <p>三輪車司機們已經醉到不省人事，散發強烈荷爾蒙和酒精的氣味，我相信他們正在粉紅色夢境裡，小心翼翼從夢境內往外窺探，看著喝醉的自己。李奧最後不停地唱著一首關於椰子酒的老歌，重複了好幾遍，醉醺醺的。</p>
      <p>
        Imna na, inna na, ipadayon!<br>
        Ipadangat an kalipay<br>
        Kultura han waray<br>
        Itagay na, Itagay na, iparayaw!<br>
        Tuba han waray waray<br>
        Imna na, itgay na<br>
        Bahalina han waray<br>
      </p>
      <p>
        喝吧，上吧<br>
        讓歡樂來到<br>
        這是瓦瑞的文化<br>
        乾了吧，炫耀吧<br>
        瓦瑞瓦瑞的椰子酒<br>
        喝吧，乾了吧<br>
        瓦瑞瓦瑞的椰子酒<br>
      </p>
    `
  },
  {
    id: 4,
    name: '五百棵香蕉樹',
    content: `
      <h5>五百棵香蕉樹</h5>
      <p>某個週六下午，我慵懶地坐在李奧的小酒吧裡，無所事事。李奧突然戴上自製的潛水鏡，當然，潛水鏡也有他的簽名「天空，李奧」（Sky, Leo）。</p>
      <p>「為什麼是『天空』？」我問道。</p>
      <p>「帶著『天空』入海，如果死掉，不會卡在礁石，會浮起來，回到天空裡。」李奧以平常心對待死亡。</p>
      <p>李奧像石塊一樣「咚」一聲潛進海裡，雙腳像魚一樣前進，身手矯捷。他迅速又熟練地採集了一些「Sarad」。「這是『Sarad』，這是博志。」他像介紹朋友一樣，將我與他採集而來的貝類互相介紹認識。「Sarad」是當地一種類似牛角蛤的大型雙殼貝類。殼形如楔子，紫褐色的外殼卻會隨著角度不同而透出殼內的七彩光澤，像是種新穎的科技媒材。外殼上端平薄而質地脆，有很好的透光性，李奧說殼可以賣錢，有人會用來製作燈罩。下端尖扁漸厚，但從殼尖端到背部相對平直，像一把刀的刀根，直直插入潮間帶泥沙底中生活，甚至藏身於二十公尺至五十公尺深的海底。殼尖端結構強韌的足絲可以將Sarad固定於泥沙之中，或牢牢地附著在海底的礁石上。在深海礁岩上的大型Sarad，只有在颱風前後，才有可能被長浪捲起沖刷到潮間帶。</p>
      <p>「像一個拳頭一樣！」李奧將拳頭作勢打在自己的臉上，誇張描述大型Sarad的貝柱尺寸。</p>
      <p>Sarad外殼有十餘條明顯的放射狀凸出線條，就像人類的肋骨，「這些線條是刀的血槽。」李奧邊說邊拿起比手掌還大的Sarad，當作刀，架在自己頸子上，然後開玩笑地伸出舌頭。接著他熟練地用刀撬開Sarad，切開連接兩片外殼的黑褐色韌帶，輕鬆分離兩片外殼。消化腺、鰓、生殖腺……貝類各個部位的形狀，總讓人直覺聯想到哺乳類動物的生殖器官。</p>
      <p>「你以後要幹嘛？回到台灣後。」李奧依序剔除消化腺、鰓和一些不可食用的部分。</p>
      <p>「種東西吧！」雖然我盯著他熟練操縱刀具的手，但腦中萌生他劃傷手指鮮血直流的畫面。</p>
      <p>Sarad有一塊橙紅色的部分是生殖腺，李奧將這部分割下，示意他要一口生吃。他吃下Sarad的生殖腺和一些我無法辨認的部位後，位於殼中央一個橢圓形的乳白色柱體露了出來。</p>
      <p>「種五百棵檸檬樹吧！」我跟他提到了我與我媽媽在Skype中的玩笑話—我們要種下五百棵檸檬樹。</p>
      <p>「要不要先在這裡種香蕉，種五百棵香蕉樹？」李奧說在菲律賓香蕉很好種。</p>
      <p>「好啊！」我回答，「可以釀香蕉酒。」</p>
      <p>乳白色的柱體就是我們所熟知的貝柱，其實是一條負責兩片殼的開合動作的肌肉—閉殼肌。我們直接將貝柱連殼放在炭火上，只需數十秒鐘就熟透，潔白如雪、口感彈牙，淋上了幾滴椰子酒，遇上炙熱的殼，酒氣迅速蒸發，我們大口吞下。</p>
      <p>我們隨口而說的玩笑話—種下五百棵香蕉樹，並沒有隨著酒、隨著食物被我們吞下肚後消化殆盡。當天我們在酒吧旁種下了第一棵香蕉樹。</p>
      <p>我們各自都幫這棵香蕉樹簽了名，Leo寫下「Banana cue」，一種菲律賓傳統街頭小吃，以芭蕉裹紅糖為炸粉然後深度油炸。我改成「Banana cute」，最後我們都同意是「Banana Q」—等待長成的香蕉樹「Banana queuing」。</p>
    `
  },
  {
    id: 5,
    name: '海燕',
    content: `
      <h5>海燕</h5>
      <p>「歡迎來到『海灘』！」李奧的口頭禪。</p>
      <p>在我替代役役期即將屆滿並打包行囊準備搭機返回台灣的前夕，我呆坐在李奧的小酒吧，一手的手肘靠在吧台桌上，手掌托住下巴，另一手則拿著一杯椰子酒，不時喝上幾口。我雙眼直直望向海與天之間，既想找到交集之處也想從中發現縫隙。我對李奧說我在學校的圖書館書架上發現一本達爾文傳記，英文和菲律賓語雙語版本，書中描寫了一種別拉德氏海燕，牠們有潛水的習性，如果別拉德氏海燕受到打擾和驚嚇時，會迅速拍打短小的翅膀直線飛行一段時間後鑽進海水裡，就像被打死了般直直落下，像石頭一樣沉，潛伏入水下一小段時間和距離，才敢再次探出頭飛出海平面。</p>
      <p>「牠一定跟我們一樣無聊啦，也是在找海天之間的縫隙！」李奧回答。</p>
      <p>其實不論在台灣或是菲律賓，都鮮少有機會看見海燕的蹤跡。</p>
      <p>從沒有效率又不時失焦的討論中，我們拼湊出「五百棵香蕉樹」，一個結構鬆散的合作計畫。</p>
      <br>
      <h5>AR</h5>
      <p>「AR他又消失了，電話不通。」我對李奧說，「但他說他已經種了五十棵香蕉樹。」對此我們同感驚訝。</p>
      <p>AR在市政府有個工作，類似處理庶務的工友，他常常抱怨薪水不高，以及自己運氣不好，懷才不遇。他十七歲結婚，不過在他十五歲時就有了第一個孩子，至今三十多歲，已經是三個孩子的父親。</p>
      <p>「要不要來種香蕉樹？」我一時興起隨口問他。</p>
      <p>「好啊。」他說。</p>
      <p>「好啊！」我說。</p>
      <p>「有沒有喝過香蕉酒？」</p>
      <p>「噁！沒有！」他皺眉頭又假裝反胃。</p>
      <p>沒過多久，他在荒廢的耕地上種下了五十棵香蕉樹。</p>
      <p>其實AR熱愛藝術，除了在市政府朝九晚五的工作外，他還是個藝術字體設計師。週末時，他會承接手繪廣告看板的委託製作案。我第一次見到他時，他正在替一間樂透彩彩券行的外牆和看板畫上他設計的標誌、標語，整間店被塗滿了鮮黃色，他紅得可以的臉，更加顯眼。「或許是因為天氣熱。」我心裡猜想。我走近向他搭訕，濃濃的酒氣隨著他呼吸的節奏陣陣瀰漫在我與他之間一個人身寬的距離裡，一公升大瓶裝的紅馬烈啤酒，就在他腳邊。我有些分不清這是屬於才華的味道，或僅僅只是酒氣。</p>
      <p>「你喜歡喝啤酒？」我也手拿啤酒。</p>
      <p>「是的！」他笑著回答，露出所剩不多，屈指可數的牙齒。</p>
      <br>
      <h5>藍迪</h5>
      <p>「藍迪（Randy）呢？」我向李奧詢問藍迪的近況。</p>
      <p>「帶學生去露營。」李奧羨慕藍迪有著正職教師的職務，但真正讓他羨慕的是藍迪常常帶學校的女學生到野外露營。「他跟學生一起種了十多棵香蕉樹，在學校裡，愛的象徵。」李奧嫉妒地說，並調侃藍迪一定都是請女學生幫忙。</p>
      <p>藍迪還很年輕，只有二十七歲。是附近中學的美術老師。他過去嗜酒如命，年輕時曾經酒駕騎摩托車，結果出了車禍，摔斷了前排牙齒，也傷了唇齒之間部分神經和肌肉。雖然缺了幾顆牙，笑容卻格外親切。後遺症是每次喝飲料都必須用吸管，不能直接就口暢飲，連喝啤酒時也是，是個突兀但協調的畫面。</p>
      <p>「一定要用香蕉葉。」藍迪說菲律賓的香蕉製品都熱愛用香蕉葉包裝，他自告奮勇要幫忙設計標誌。 他將設計標誌的收入都捐獻給學生營隊活動。</p>
      <p>臨走前，我們約好十一月喝酒，等我再次回到獨魯萬市時。</p>
      <p>「Text me la !」他拉了長長的尾音，我們互相道別。</p>
      <br>
      <h5>克里斯</h5>
      <p>「克里斯（Chris）還好吧？」李奧問我，但表情不願顯露擔心。</p>
      <p>「好很多了。」我回答。</p>
      <p>克里斯是個虔誠的天主教徒，在我服役的學校擔任圖書館管理員。他會利用空檔時間，剪剪貼貼出一幅拼貼畫，內容都是關於他自身的處境—他說著家裡缺乏自來水系統，他說著每天每天苦等水車到來，裝水、提水的大量身體勞動， 他說著大型賣場興建之後如何壟斷飲用水系統。</p>
      <p>我偷偷觀察他的創作過程，他表情害羞、口氣謙虛說著自己不知該如何進行創作。他最喜歡畫國旗， 在他替學校設計的海報作品中，一定都會出現一面大大的菲律賓國旗。</p>
      <p>他一天一定會吃兩次點心，點心時間是他創作欲最強的時刻。</p>
      <p>「你不吃點心嗎？」「菲律賓人一天一定會吃兩次點心。」他笑著跟我說，也分給我一些屬於他自己的三明治。他拿在左手上的三明治都還沒品嘗半口，右手上的鉛筆已經完成一份草稿，是關於學校的農業教育，他說他想品嘗自己種下的蔬菜。</p>
      <p>「要不要來種香蕉樹？釀香蕉酒？」我一時興起隨口問他。</p>
      <p>「好啊。等我開刀回來之後。」「我要種一百棵。」他說。</p>
      <p>「媽媽，我想上帝是在召喚我，我是受到祝福的。」 克里斯說他腦中有一顆瘤，但他感謝上帝。</p>
      <p>慶幸的是，在學校與教會的支持下，手術得以順利進行。手術之後，不減克里斯對藝術的熱情，而且用色依舊鮮艷。</p>
      <br>
      <h5>李奧</h5>
      <p>「回去後你會種檸檬樹嗎？」</p>
      <p>「會吧。」事實上我不太確定。</p>
      <p>我們討論可以將李奧自釀的椰子酒進行蒸餾成椰子口味白蘭地，再以椰子白蘭地為基酒再製成香蕉酒和檸檬酒，我們想像在台灣的我和在菲律賓的李奧，時區的上下兩端，連結成為一個生產線。</p>
      <p>「你要種幾棵？」李奧問我。</p>
      <p>「五百棵。」我說。</p>
      <p>我招手攔下一台寫著往「聖荷西」（San Jose）和機場的吉普尼，待機、轉機再加上飛行時間，台灣與菲律賓，不遠的距離，我卻足足花了一天的時間才返抵國門。</p>
      <p>我著手計畫著《五百棵檸檬樹》，也不時搜尋特價機票，預計十一月再回到獨魯萬市，繼續種下香蕉樹。</p>
      <br>
      <h5>海燕</h5>
      <p>我打了許多次國際電話給李奧，無人接聽，隨後緊接著是電信公司制式化的廣告，字正腔圓。依序播放英文和菲律賓語的版本，不停重複。</p>
      <p>我查看維基百科，「海燕」，二〇一三年太平洋颱風季第二十八個被命名的風暴，是二〇一三年度全球最強的熱帶氣旋、二十一世紀至今該區域的最強風暴，以及該區域史上第二強的熱帶氣旋，並創下登陸時最強風暴的紀錄。「海燕」一名由中國提供，是指海燕鳥。</p>
      <p>十一月初，強烈颱風海燕重創菲律賓中部，獨魯萬市首當其衝，海燕颱風每小時三百一十五公里的風速和惡水一夜肆虐，滿目瘡痍。看著不停更新的即時網路新聞和持續攀升的傷亡人數統計，我想著李奧戴起他的潛水鏡，簽上有他親筆簽上「天空」一字的潛水鏡，潛進深海裡；我想著AR又去尋找他的名字裡缺少的英文字母「Ｉ」了，可以讓他變得像空氣一樣輕，飄回到半空中的「Ｉ」；我想著藍迪將無數的紙板混合香蕉葉編成舟，飄浮起來，載著他最愛的學生們；「哈囉，克里斯！」別忘了我們說過的出版計畫，然後我想到了，封面就用你最愛、也最迫切需要的水藍色吧。</p>
      <p>天空的反面是海，天上飛的鳥與海裡游的魚，都會加入我們的旅程，在海與天的交際之間。</p>
      <p>我再次輸入李奧的手機號碼，無人接聽，廣告內容則做了更新。</p>
    `
  },
  {
    id: 6,
    name: '檸檬樹的成長',
    content: `
      <h5>檸檬樹的長成</h5>
      <p>
        洇水（im-tsuí  灌溉）<br>
        一水花（tsi̍t-tsuí-hue 一季開花）<br>
        翻土（pong- thóo 把土翻鬆）<br>
        搏嶺（puah-líng  做小土坡）<br>
        交花（kau-hue  幫花做交配）<br>
        割藤（kuah-tîn  去除多的藤蔓）<br>
        攪肥（kauh-puî  攪拌肥料）<br>
        發酵（huat-kà  肥料發酵）<br>
        吹南風（thàu-lâm-hong 夏季下雨前吹的風）<br>
        拼溪底（piànn-khe-té 大水後將沖積而成的河灘地開發為新的農地）<br>
        捻茵籽（iàm-hio̍h-á／tiah-ínn-á 捻葉／摘蘡，把多的新芽去除）<br>
      </p>
      <p>二〇一三年的最後一天，我們在觀音的休耕田種下了第一批檸檬樹。在種植檸檬樹前，我很矯情地反覆聽了王榆鈞與時間樂隊的〈檸檬樹的長成〉。不約而同，有好幾位朋友都傳了這首歌的連結給我。</p>
      <p>這是一首用閩南語所吟唱的歌曲，歌詞是種植檸檬樹過程中的層層步驟。很快我就發現，南、北台灣風土氣候上的差異，種植步驟有些許不同，至少，想像或許是相同的。</p>
      <p>（事實上，欠缺關於柑橘類栽種和病蟲害防治知識的我，種得糟透了！）</p>
    `
  },
  {
    id: 7,
    name: '檸檬樹上的斑鳩',
    content: `
      <h5>檸檬樹上的斑鳩</h5>
      <p>每天早上，老媽用不再鮮艷的塑膠勺子舀起滿滿一勺的鳥飼料。她走出門外，「喀、喀、喀」，快要對不齊門框的紗窗門老是在開啟和關上之間發出不自然的摩擦聲。耳尖的三隻狗聽見聲音後興奮地起身，其中一隻體重過胖，毛髮被修剪得像綿羊般的黃金獵犬「哈利」飛快靠近老媽，源源不絕帶著欲望的口水從牠粉嫩的舌尖流下。</p>
      <p>「這不是給你吃的啦！」老媽大聲斥責緊黏她褲腳貼身而行的哈利。</p>
      <p>牠流下的口水被風一吹，剛好就停在老媽的褲腳上了。</p>
      <p>說牠懂事其實很懂事的哈利聽到訓話聲後，乖乖收起欲望，收起口水直流的舌頭，蹲坐在老媽的身後，哈利像是等待戲劇開演緊盯舞台上僅有線索的觀眾，牠直直盯著塑膠勺子裡的鳥飼料。</p>
      <p>老媽熟練地將飼料均勻撒在前庭的空地上，跟個表演者一樣，她用鳥飼料畫出好幾條長短不一的直線，由上到下、由左到右，她俐落地做了個結尾，用塑膠勺子敲了兩下水泥地板，將勺子內的飼料全部抖出。</p>
      <p>這是她與斑鳩們溝通的一種符號，也像是一種書寫，一種文字。斑鳩是最先到來的，有種說不出的默契正在建立，正在不知不覺中被累積。牠們安靜又輕巧地在黑色電纜線上排排站好，不時發出「咕咕～咕」的叫聲，而且還重複了好多次，邊叫邊等著老媽日復一日儀式性的書寫結束，等著好好享用彼此之間的默契。</p>
      <p>「這不是給你吃的啦！」老媽再次叮嚀蹲在一旁，不知是在妄想還是單純望向鳥飼料的哈利。</p>
      <p>哈利其實是個老實人，看見正在享用飼料的斑鳩，很識相地繞道而行，雖然偶爾還是會偷吃幾口對牠而言口感過硬的鳥飼料，被發現時更會低下頭「哈哈、哈、哈」喘著氣逃開。</p>
      <p>「現在有很多鳥來吃，有麻雀、斑鳩、藍鵲還有許多奇奇怪怪的鳥，不到下午就全部吃光光、吃光光。」</p>
      <p>才接近中午，飼料已經被吃得精光。對於斑鳩有獨特情感的老媽深怕牠們還是餓著肚子，她又拿起不再鮮艷的塑膠勺子舀起滿滿一勺的鳥飼料，在前庭畫出像符號又像文字的線條。</p>
      <p>我看了廚房牆上慢了十五分鐘的老時鐘，走進廚房旁的塑膠玻璃屋內，風和雨的味道比真正的風雨還要早就來到，外頭一切看來沒有什麼不同，沒有任何徵兆。但不出所料，大雨來得突然，老媽說以前常常都有這種西北雨，遠遠就可以看見雨一路下過來，像是會追人。</p>
      <p>雨水打在玻璃屋的塑膠浪板上產生的聲音，直接打在頭上不停敲響我的腦門，急迫地想要傳達給我什麼訊息似的。</p>
      <p>玻璃屋牆邊有好幾大串因為連日的大風大雨而掉落的香蕉，就在今天，不可思議地，被強迫後熟的香蕉在昨天一夜之間由青轉黃，香蕉成熟速度之快，讓人有些措手不及。</p>
      <p>「吃起來像番薯一樣，沒有甜味、沒有香氣。」老媽抱怨牆邊成堆但不知該如何處理的香蕉。</p>
      <p>天色已經變得像泡了過久的濃茶，我口裡嚼著像番薯一樣的香蕉，雨還是很大，窗外十幾隻斑鳩不知從哪裡而來，如同魔術師帽子戲法裡突然變出的鴿子。牠們不飛翔，緩緩地走向前庭，走向飼料的周圍，踱步穿梭在像符號又像文字的線條間，輕盈、優雅。「牠們都是熟面孔了！」老媽似乎可以從牠們的動作判斷，又或是聲音？「咕咕∼咕」斑鳩不停叫著，前胸也跟著叫聲而脹大，也不忘用深灰色的喙啄食水泥地板上的飼料，彷彿道謝般拚命點頭如搗蒜。雨勢似乎沒有減緩的意思，雨珠顯得無情，拚命打在斑鳩們的身上，斑鳩沒有受到任何影響，依舊維持相同的節奏踱步、點頭、「咕咕∼咕」地叫。</p>
      <p>有幾隻白色斑鳩偷偷潛近屬於哈利的飼料盆，步伐更加輕巧緩慢又謹慎，就怕驚動了正在熟睡的哈利。不過麵包樹的果實落下「砰」一聲，跟網球落地的聲音有異曲同工之妙，讓這隻白色斑鳩嚇了一跳，最後還是安分回過頭去吃地上的飼料。</p>
      <p>藍鵲來了！</p>
      <p>老媽說藍鵲很霸道，「長得漂漂亮亮但是又兇又壞。」老媽有些氣憤地說。藍鵲利用相較之下略顯優勢的體形，硬是將斑鳩們驅離後才開始大快朵頤，張大翅膀，一推一擠，十幾隻斑鳩嚇得不敢靠近，紛紛飛回電纜線上頭，眼睜睜看著藍鵲將飼料啄食殆盡。其中有兩隻斑鳩卻往反方向飛去，停在山上一棵檸檬樹上。</p>
      <p>檸檬樹上的斑鳩不停「咕咕∼咕」地叫，一灰一白輪流叫著像是對話。怎麼會有白色的斑鳩？這讓我記起自己小時候一個有趣的故事。</p>
      <p>天氣好的時候，觀音老家附近休耕的水稻田中的高壓電塔上，站滿了烏鴉、麻雀、鴿子、斑鳩，可是一下起大雨，卻統統都不見蹤影，我充滿好奇心到處詢問這些鳥都到哪去了，特別是白色的烏鴉、白色的麻雀、白色的鴿子、白色的斑鳩。</p>
      <p>終於，學校裡新來的年輕實習女老師不厭其煩回答了我的問題。</p>
      <p>
        「下大雨的時候停在電塔纜線上的鳥都到哪裡去了？」<br>
        「烏鴉、麻雀、鴿子、斑鳩……牠們都會先被雨水給洗白，變成白色的烏鴉、白色的麻雀、白色的鴿子、白色的斑鳩。」<br>
        「是生病嗎？都變成白色的？所以白色的鳥都是生病的鳥？」<br>
        「不是噢，牠們其實是最純潔的鳥。下雨天的時候，牠們被雨水一直洗，一直洗，然後慢慢開始變透明，那種你看不見的透明，比冰還透明。」<br>
        「然後呢？」<br>
        「牠們慢慢溶化成了水，跟雨水一起流到水圳，流到大海。」<br>
        「牠們和海裡面的魚做了交易，給了魚一雙翅膀，所以你會看見飛出水面的飛魚。」<br>
        「如果希望牠們不要消失呢？」<br>
        「牠們喜歡果樹，特別是白色的鳥。如果你多種一些果樹，牠們就不會飛走了！牠們最喜歡躲在果樹裡了！」
      </p>
      <p>
        檸檬樹上的斑鳩並沒有因為發現我而驚慌失措，我身上金黃色過度耀眼的雨衣似乎也沒有對兩隻斑鳩造成威脅。我靠在檸檬樹下，我摘了好幾顆檸檬，每次摘檸檬時，都會想起女朋友傳給我的電子卡片上，有她親手寫上的經典立志格言：<br>
          If You Have a Lemon, Make Lemonade.<br>
        「如果你有一顆檸檬，做檸檬汁吧！」<br>
        比起檸檬汁，我通常都拿來調酒比較多。<br>
        「我們需要聚集在一起，在這棵尚未種植的檸檬樹下。」 斑鳩、檸檬和我們都需要靠在檸檬樹之下。手拿檸檬，我對老媽說：「我們來種檸檬吧！」<br>
        「要種幾棵？」老媽好奇地問。<br>
        「五百棵！」我說。
      </p>
      <p>至於那些發育不全又被強迫後熟的香蕉，我剝了好幾根給貪吃的哈利品嘗，但牠只吃了一口。</p>
    `
  },
  {
    id: 8,
    name: '死亡',
    content: `
      <h5>死亡</h5>
      <p>我該如何看待死亡？</p>
      <p>我從網路串流電影中去拼湊對於死亡的想像，在緊盯螢幕不停快轉、迴轉、暫停之時，我卻想起幾天前生活中一些枝微末節的小事，例如等公車時旁邊情侶之間的耳語，她說她今晚想吃咖哩，而他卻只想靜一靜，又或是便利商店裡的促銷標語（沒有打動我購買的欲望）。當兩輛公車交錯時，與對面座位上乘客短暫的四目交錯，我們反射性地迴避彼此的目光，卻又用眼角餘光仔細打量。這些是如此無關緊要的時刻。小時候，我以為死亡會像按下播放器暫停鈕一樣，讓時間因此停止，但軀體死亡後很快就會腐爛，屍體在空氣中、水中和埋在地下腐敗所需時間的比例關係為一：二：八 。</p>
      <p>清晨的檸檬園只有陽光和寂靜，我的四周是隨風搖擺的檸檬樹。（還是檸檬樹的搖擺招來了風？我無法分辨。）踩在四月溼潤的田地上，軟爛發臭的枯葉是已經分解的春天，翻開近乎熟成的落葉堆肥，大小各異的蟲子畏懼我的身影和突如其來的日光，紛紛躲避。身旁的土牆邊有個發酵桶，桶內是發酵到一半的黃豆渣，因為加入了柑橘皮，原本豆渣發酵讓人作嘔的氣味，反而有了令人垂涎的果香，像是柑橘口味的小米酒。但那之後是野狗腐爛的氣味，湧入我的鼻腔。我戰戰兢兢，朝身邊一棵及肩的檸檬樹身挪移，那邊有隻狗正在腐爛，味道都飄了出來。為了防治成群結派的野狗獵食圈養在戶外的土雞，當地農夫採摘四處可見的曼陀羅花或種子，混合大量狗飼料，誘殺野狗。曼陀羅全株有毒含大量東莨菪鹼，特別是種子部分濃度最高。我想像著野狗在適量、過量跟大量濫用曼陀羅之間，牠的意識也從鎮定到致幻到癱瘓。他們並不會埋葬衰竭而死的狗，反而讓味道飄散，讓人不禁掩鼻的味道，是充滿警告意味的訊息。</p>
      <p>我該如何形容一個人的死亡？</p>
      <p>計畫書中的老農，在我寫下計畫書不久後便辭世，近九十歲的老農，我的爺爺。我已經記不清他的長相，但我卻還記得等紅綠燈時，對向迎面而來，匆匆一瞥的行人—長相，甚至是表情。</p>
      <p>一菸二酒三檳榔的胖道士侃侃而談死亡的大道理，我緊盯他血紅的雙唇和染色的牙齒。道士的結論，總歸一句「時也命也，不可違逆」。胖道士手腕上的電子錶鬧鐘響起「嗶、嗶、嗶」，他說時辰已到。道士邊走邊跳邊誦經，繞著金童，繞著玉女，繞著正被烈火吞噬的獨棟大別墅。</p>
      <p>我們盯著烈火，盯著金童，盯著玉女，盯著金色頭髮的司機，盯著他不太自然的微笑。被烈火團團包圍的他們正跨越今生與來世，他們是否正從西方的極樂盯著面無表情的我們，又或者他們的眼中只有眼前的熊熊烈火。</p>
      <p>圍著烈火，雙眼乾澀皮膚灼熱。當烈火燒盡一切，唯一留下的是臉上還沒退去的溫度。那時剛好是冬天，感覺很暖很暖。</p>
      <p>我問老媽覺得死亡到底是怎麼一回事，她說：「死亡是人生最公平的一件事情。老與病只是一種訊息，時間不同卻會公平地來到。」她從沒有談過外公外婆的去世，一如往常，說了個故事。</p>
      <p>老媽說自己也和外婆共享同一個關於死亡的夢，關於藍色斑鳩的夢。她們常常會到河邊洗衣服，外婆洗大件，老媽就洗小件。那時是老媽的二哥失蹤下落未明之際，外婆對老媽說自己最近常常做一個夢，夢見站在樹梢上的藍色斑鳩，藍色斑鳩突然開起口來對外婆說：「我變成了一隻小鳥飛回來，看見你在河邊洗衣服，一邊洗一邊哭。」</p>
      <p>我知道她們兩個都相信，死亡並不是去世，而是回世，回到一個更適合自己的位置。</p>
    `
  },
  {
    id: 9,
    name: '蚊字',
    content: `
      <h5>蚊字（文／徐月珍）</h5>
      <p>承蒙承蒙</p>
      <p>兒子在台北工作，常常抽空回鄉下，脫下輕便的服裝換上工人裝，沒有耕種下田過，不會做農事，先從拿鋤頭開始，學習除草，我常跟他開玩笑，上午是藝術家拿小枝筆，下午是農夫拿大枝筆（鋤頭），說起來好像輕鬆自在的，其實兒子已汗流浹背、全身溼透，看他穿梭在檸檬園疲憊的身影，讓人心疼不已，心想當初為何會忘記阻止他的計畫，而且剛開始種植的時候，親朋好友都不鼓勵，他們以過來人的身分和經驗告訴兒子，種植農作物是件非常辛苦的工作，但是兒子還是堅持實踐，對著五百名認購者的誠信和責任，埋頭苦幹全力以赴，也因他的認真和努力，讓其他種植者有了轉念，附近有塊荒廢許久的葡萄柚園，聽說是因為我們的工作熱誠和態度感染到他，讓他有了動力，把葡萄柚園重新整頓，也增加了一筆收入，好想為他歡呼一下！</p>
      <p>我有個叔叔已經八十幾歲了，偶爾會拄著拐杖、帶著鼓勵和關心來檸檬園，兒子一聲叔公，叔叔開心得不得了，爽朗的笑聲穿越整座山林，還用他宏亮的聲音說，這是他這幾十年來看到最認真的年輕人，兒子直說叔公您太誇獎我了，（承蒙承蒙）謝謝的意思。</p>
      <p>如果因夢想而熱血，也只有親身體驗過才知箇中滋味，酸甜苦辣都有，所以有辛酸也有甘甜，每次看到提供土地的弟弟關愛的眼神，就已溫暖我整顆心，還有小時候認識的鄰居和親戚，不管是騎車、開車或徒步上來，是關心還是鼓勵或是好奇心，總之這塊檸檬園讓我們這些年過半百的人，在此重聚、閒聊，有此機會也是無比幸運。</p>
      <br>
      <h5>烏秋</h5>
      <p>車聲、笑聲、蟲鳴鳥叫聲，不知是烏鴉還是烏秋，羽毛黑到發亮，我傻傻分不清，牠總是佇立在竹竿上頭，吱吱呀呀地叫不停，彷彿告訴我們牠來了，而且是愈來愈靠近我們，因為我們只要把草輕輕地撥動，牠就可飽餐一頓。在山上彼此的交談和溝通是很自在和豪邁的大聲嚷嚷和吆喝，是很稀鬆平常的事，就像喝茶一樣，大口大口地喝。因為無拘無束，大家互動就更加頻繁和緊密，感情也更加深厚，整個檸檬園經常充滿着歡樂喜悅，而我的內心也更加富裕。</p>
      <br>
      <h5>蚊字</h5>
      <p>有一天朋友打電話來，我剛好在檸檬園工作，因連續下了幾天雨，草長得特別茂盛，蚊子也特別多，雖然全身包得密不透風，防蚊液也擦了，蚊子照樣把我叮得滿頭包，突然手機響了，朋友很有禮貌的一聲，大姐，（我有看到農地長蚊子）對啊對啊蚊子超多的趕都趕不走，把我耳朵叮得紅紅腫腫的像豬頭皮一樣，我自己一個人滔滔不絕講個不停，而朋友是笑翻了，大姐、大姐冷靜一下，你一定是被蚊子搞瘋了，才這麼激動，此蚊非彼文啦！是報章雜誌有報導說你兒子種檸檬好文青，農地長文字，原來我把文字聽成蚊子，換我自己笑到胃都快抽筋了，朋友還笑說這麼美麗的誤會，你兒子可以寫一篇蚊子大戰，挺有趣喔。</p>
      <br>
      <h5>颱風</h5>
      <p>今年兩個颱風重創台東跟南部，造成農民損失慘重，看了心裡很難過，北部沒影響，心想我的檸檬樹逃過一劫，沒想到第三個颱風又要來，真的讓我們蠻擔心，因為去年的颱風就把我們的檸檬樹摧殘得很慘，正在擔心的時候，外面已風雨交加，而且風雨愈來愈大，檸檬樹肯定經不起這次的狂風暴雨，後來風雨一停我們立刻去檸檬園看，真的慘不忍睹，倒的倒、趴的趴，有些連根被拔起，第二天趕緊請人把它扶正、綁好，希望它能慢慢長回去，才能把損失降低。</p>
      <br>
      <h5>天牛</h5>
      <p>除了天災，蟲害也是挺嚴重，自從種了檸檬樹之後，認識了很多昆蟲和小動物，天上飛的、地上爬的像什麼蚜蟲、潛葉蟲、粉蝨、薊馬……以前也不（是）沒見過，這些細細小小的蟲，專搞破壞，有一種白色像棉花，一坨一坨黏在樹枝上或躲在葉子背面，（很）多時候看了會起雞皮疙瘩，挺嚇人的，有牠的地方，葉子慢慢會黃化，不把它弄掉的話整棵樹會被吸乾而枯死，牠就是討厭的粉介殼蟲。一年四季，每個月都有不同的蟲出現，有一種三月始灰象鼻蟲，我以為都是灰色的，當我看到有綠色象鼻蟲的時候，我還以為牠是檸檬葉吃多了，身體變綠色，可能我被這些蟲搞得有點抓狂，才有這種直覺吧，後來才知道牠是樁象類的角肩椿象，也叫大綠或南方綠椿象，牠會吸食果實汁液，形成果皮硬斑。破壞力很強（的）天牛也是讓人聞之色變的天敵，第一次在檸檬園發現的時候，還不太在意，也沒即時行動做好防護的措施，認為檸檬樹的樹幹還細，天牛沒興趣，因為天牛要下蛋，牠會找樹幹比較粗的，後來發現粗細牠都不放過，因為有幾棵檸檬樹葉子莫名其妙一直掉，果子也長不大才驚覺樹枝也乾了，只好把它剪掉，終於發現天牛幼蟲在裡面啃食，再繼續檢查其他的樹又發現一棵樹被天牛挖兩個洞，裡面是空心，原來早就被天牛幼蟲掏空，天牛已飛走，樹也奄奄一息，總之天牛就是可以把一棵大樹給搞死，夠恐怖吧？</p>
      <br>
      <h5>草木共生</h5>
      <p>記得剛整完地的時候，整片都光禿禿的，現在是檸檬樹跟雜草共生，變成整片綠油油的檸檬園，剛開始自己沒有種植經驗，剛好又找了一位沒有果園經驗的師傅，他只會開挖一般的工程，整塊地也沒做好分段，還把底層的泥土挖上來，把最上層最肥沃的腐植土覆蓋在底下，看到那一幕，真的傻眼，很無奈，沒辦法沒時間改了，只能勉強將種苗先種下去（因為種苗不能放太久），種了一大段時間，有兩小塊連一根草都長不出來，而檸檬樹定期施了有機肥也長不起來，是肥料不足養分不夠，還是其他原因，不知道，後來想想決定把它移植到土質比較肥沃的地方，讓它重生，現在看起來好多了，目前檸檬園雖然沒有很完美，也還沒達到我們想要的美觀和舒服，但是我們還是繼續在做調整和修剪，像草的部分我喜歡整片剪得光溜溜，一寸不留，因為我怕蛇會躲在草叢嚇人，蛇我不是普通怕，是非常、非常地怕，所以我希望檸檬園理光頭。可是兒子認為草可保溼（的確是如此），剪太短可惜，他想把檸檬園整理成一片大草坪，踩下去像地毯一樣柔軟和舒服，目前我們正朝這方向努力中。</p>
    `
  },
  {
    id: 10,
    name: '農工交雜',
    content: `
      <h5>區間車</h5>
      <p>其實檸檬田不遠，從台北自行開車南下大約一個多小時，但天未光，在筆直的高速公路上，常常會有錯覺，覺得自己並沒有移動，太陽升起的瞬間像解除魔咒的暗號，可以讓人清醒，睡眼依舊惺忪，沒多久，夏天熾熱的陽光，晒在臉上，像被打了一個耳光般刺痛。如果沒有開車，我會選擇坐火車，清晨時段車班內的乘客通常都不發一語，閉目養神，頭或仰或俯，我好像是最清醒，但也好像是最昏沉的。我在坐火車的時候完成了《五百棵檸檬樹》的提案。我喜歡區間車加速向前，鋼輪摩擦鐵軌後持續傳來的震動，或是每次列車進站，煞車皮散發出的焦香味。</p>
      <p>起初，計畫中預定種下檸檬樹的三塊休耕地分別在新竹縣新埔、關西和桃園市觀音區，三塊土地上的人、事、物都被相似的歷史結構所影響，但對我母親而言，所謂的歷史結構，比不上「命運」兩字，根深柢固地主導著他們的認知。</p>
      <p>一九五〇年冷戰、反共、戒嚴體制在台灣確立後，美國藉由其「美援總署駐台分署」間接主導台灣進行「以農養工」的農業改革政策，例如一九五〇年代實施土地改革、肥料換穀、低糧價等政策，將農業的成果做為累積工業發展的資本，強制擠壓農村過剩的勞動力，其中大批釋出的年輕女性勞動人口湧入勞力密集的製造業，農工之間的不平等交換也擴大了城鄉差距。</p>
      <p>一九六五年，美國終止在台歷時十五年的經濟援助，台灣經濟部在美國的規畫與壓力下，施行「加工出口區設置管理條例」，設立加工出口區，勞力密集產業出口導向之經濟發展政策，正式成為跨國資本生產鏈的其中一環。 </p>
      <br>
      <h5>新埔</h5>
      <p>一九六〇年代中期新埔鎮在鄉村工業化的脈絡下分別成立內立和文山兩個小型工業區，遠東紡織再以大型工廠之姿進駐工業區，分別在一九六九年設立亞東化纖廠（即遠東化纖廠）、遠東製衣廠、一九七〇年設立遠東紡紗廠。</p>
      <p>天還沒亮，紡織廠的交通車沿路接送上下班的員工，幾乎都是女孩，身上穿了件淡淡蘋果綠的制服。老媽心中充滿好奇，偷偷地觀察停在馬路邊的交通車，車門一開，下班的員工使得空氣中飄來一陣微弱卻不好聞的氣息，是由下班員工疲憊的表情加上淡蘋果綠制服上滿滿的棉絮所散發出來。三班人力輪班的紡織廠不眠不休地運作，一台一台的交通車跑遍窮鄉僻野，收集妄想可以在紡織廠裡編織自己夢想的年輕女孩。</p>
      <p>老媽曾經說過那跑遍窮鄉僻野的交通車，指的就是遠東紡織的交通車。其實她自己也沒有逃出這個大結構之外，也捲進了資本主義的生產體制之下。</p>
      <p>前面有六個像籃球場那麼大的立體方塊，裡面都注滿了水，我好奇用手指頭輕碰其中一小角，冰冰涼涼的，是水，我輕輕嘗了一口，如海水一樣鹹苦。除此之外，水中還充滿了大大小小、肉眼都快要無法辨識的棉絮。我無法將水嚥下去，因為棉絮正在我喉頭打轉搞得我直發癢，我大聲咳了出來，口中的棉絮變成了一顆顆小球，像羊毛氈做成的球。</p>
      <p>突然間一個一個年紀介於十六到二十五歲的女孩們，很有秩序和節奏往這些方塊走去。</p>
      <p>「小心啊！ 會被淹死啊！」我放聲大叫試圖阻止她們進到方塊中。</p>
      <p>「沒問題的！」帶著傻傻微笑的女孩這麼跟我說，「你也一起來吧！」</p>
      <p>我仔細一看，女孩的長相特徵與我還真有幾分神似。她帶著微笑走進方塊中，馬上就被水中的棉絮層層包圍，一層又一層，各式各樣顏色和大小的棉絮自動黏在她身上，她看起來就跟熱帶魚一樣自在。每過一段時間，會從口中、鼻中清理出一顆顆小球，像羊毛氈做成的球。球慢慢浮到了立方體的最頂端，當立方體的頂端浮滿小球時，也結束了一天的工作。</p>
      <br>
      <h5>九〇年代</h5>
      <p>九〇年代起一波波的休耕政策—從一九八四年輔導農民轉作，到一九九七年改以「水旱田利用調整計畫」（the adjustment of paddy field and upland utilization），大規模以補助金鼓勵休耕，再到二〇〇二年加入世界貿易組織（WTO），開放進口稻米，迫使更多農民向休耕補助政策靠攏。而鄉村型工業區、加工出口區持續地像虹吸效應般吸納鄉村勞動人口。隨著新自由主義全球化的發展，台灣的勞力密集產業幾乎全面外移至勞動力更便宜的區域，當然，老媽所從事的成衣產業也不例外。</p>
      <br>
      <h5>五百棵檸檬樹</h5>
      <p>「原裝萊比錫高級啤酒，能讓任何ㄧ輛車快到飛起來，就像一種金色的瓶中精靈，將我們的頭髮輕輕撩起，輕鬆飛越高牆，借給我他們的飛毯，讓我們的身體乘著它飛行，朝條子腦袋吐口水。」<br>
        —《當我們做夢時》（Als wir träumten）</p>
      <p>回過頭來，該如何面對休耕了四、五十年的土地？</p>
      <p>我都在楊梅站下車，伴隨一陣煞車皮散發出的焦香味。我往停車場方向走去。我開著福特銀色Tierra，跨越新竹、桃園兩縣市的邊界，往檸檬田駛去。我想，不論歷史結構為何，當我們持續做夢時，瓶中的精靈，便會乍現。以及雖然我們沒有萊比錫啤酒，但有五十六度的檸檬酒，顏色金黃，足以暖身，做為起飛前的初步熱身。</p>
      <br>
      <h5>早餐</h5>
      <p>心裡想著要到廟埕前的小吃攤吃一碗肉粥、搭配一盤紅燒肉做為早餐，卻往街邊的水餃攤走去。點了一盤水餃，十五顆，我大口吞下水餃，心裡暗自默數：一顆、兩顆、三顆，數著數著反而讓自己忘了如何算數。這種類似的日常情節層出不窮，像是開啟手機中的google map，走著走著卻頓失方向感；互相擁抱，抱著抱著身體像消失般輕盈—自己與對方的重量到哪裡去了？ </p>
      <br>
      <h5>區間車</h5>
      <p>早餐過後，我搭上清晨五點從台北出發到楊梅的區間車，有時候可以碰到些熟識的臉孔，我遵守彼此之間的心照不宣—不相互問好、面無表情、別過頭去。所有人似乎都害怕和對面座位的人對上眼，絕大多數的人選擇閉目養神（可以清楚看出來根本沒睡著）。我喜歡透過車窗看窗外的風景，雖然向外望去，沿途的風景淨是些設計風格千篇一律的磚頭房子，或是缺乏修繕的圍籬，並沒有太多吸引人的東西。充分享受這種無聊需要耐心，幸好眼前這片厚重的玻璃像個濾鏡，可以過濾掉風景中頻頻發出讓人煩心又吵雜的頻率。總是需要找些理由歸咎無法整理的情緒。很多時候，這般難受的煩躁來自於自己，期待從只有〇．一秒鐘不到的畫面中找到些有趣的元素，是過分強求。事實上，期待通常落空，連同難以捕捉的風景無奈地逝去。</p>
      <p>這些風景是誰所造就的啊？又到底是誰的決定不停影響我們的生活？我恨不得把所有的東西都拆了。</p>
      <br>
      <h5>香氣</h5>
      <p>下了火車，我往停車場走去，我的銀色福特Tierra中塞滿了檸檬樹苗，從後照鏡看去是一整片的綠色。經年整修不斷的產業道路，路面從沒平過。楊梅到觀音，二十分鐘的車程，顛簸的路面震得檸檬葉相互摩擦飄出陣陣檸檬香。許多植物的氣味是用來和動物交流資訊的複雜工具，有時我會想：我是不是被這陣陣的檸檬香給欺騙了，就像我用昆蟲香水（昆蟲性費洛蒙）所製作的桃色陷阱，誘殺專情的昆蟲，我是不是也不知不覺掉進陷阱裡了？從小蒼蠅到大猩猩，為了獲取社群中的權力、為了繁衍，無時無刻都在利用費洛蒙傳遞訊息甚至溝通。《科學》雜誌一篇引發爭議的文章指出，女人的眼淚雖然沒有味道但是和負面情緒有關連，而男人在聞到女人眼淚之後，不僅睪固酮分泌量下降，興奮勃起的次數也會減少。幸好，我聞到的不是眼淚的味道。</p>
      <br>
      <h5>觀音</h5>
      <p>我繼續聞著車內的香氣，前往目的地觀音。觀音，一個位於桃園縣、靠近西部沿海的鄉鎮，相傳先民在當地水池發現一塊石頭上的紋路神似觀音菩薩，因而得名。</p>
      <p>味道一成不變的觀音老家是一棟兩層樓的老房子，有一道樓梯連接上下兩端，從過去至今，拉扯出許多情緒的正負兩端。樓梯轉角處的一面白牆老是潮溼不堪，白牆跨不過晴雨交雜的折磨，一顆顆溼漉的水珠硬是從具有防止壁癌功效的水泥漆裡頭鑽了出來，水珠摸起來的觸感有些滑嫩黏膩，很像受傷的傷口產生的組織液，有時微微酸臭，類似成熟過頭的芭樂。不過我們不是躲不開天氣折磨的白牆，我們可以跨越情緒的兩端。</p>
      <p>老家剛好位在桃園國際機場其中一條飛機航線底下，我並不羨慕在機艙內正劃過天際的旅客，我想要擁有的是在機場內等待登機前的時刻。明亮挑高的機場大廳充滿零碎細瑣的聲音，像浮游生物般蠕動並漂浮著，集結又各自離散，人的移動直接推動了這些像生物的聲音，有時一團一團、有時一波一波，混雜著空氣，那是令我麻醉的氛圍。在檸檬園中，也可以發現這種像生物般環繞在四周的聲音，被風所驅動，但被葉子所驅趕。</p>
      <br>
      <h5>工廠</h5>
      <p>二十多年前，老媽利用了自己的地緣優勢，在老家附近，租了間雖然簡陋但應有盡有的鐵皮屋廠房，一通一通電話號召了個個身懷絕技的裁縫老手。一台一台的二手電動裁縫機台，接二連三被吃力地搬了進來。一切就緒，老媽開始接起了外銷歐美的訂單—彩色桌墊的加工，只有幾道簡單的車工，換來相對微薄的工角。五、六位阿姨各自負責不同的顏色，她們像軍隊般有秩序地前進，一天八小時、九小時、十小時。</p>
      <p>這間位在二樓的鐵皮加蓋小型加工廠是老媽孤注一擲下的結晶。老媽說自己著了魔一般，再次相信必須要從成衣產業裡尋找未來。她決定賭上自己最後一個好奇心，希望剝開後不是碎落滿地像淚珠一樣大的結晶。</p>
      <p>二十年後，我並沒有那麼勇敢地全然賭上我的所有。我只是一股腦地想要拆光老家內所有的隔間，所有不合時宜的裝飾、所有不符合期待的風景，我想要找到躲在角落裡那像生物般的聲音，讓牠們爬進我的耳朵裡，暫時麻醉一下我自己。</p>
    `
  }
]

export const SimpleSwiperWithParams = ({ click, menuList }) => {
  const params = {
    modules: [Pagination, Navigation],
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination.huang-swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next.huang-swiper-button-next',
      prevEl: '.swiper-button-prev.huang-swiper-button-prev'
    },
    containerClass: 'huang-swiper-container',
    loop: true,
    loopFillGroupWithBlank: true,
  }

  return(
    <Swiper {...params}>
      {menuList.map((item, i) => {
        return (
          <div 
            key={i} 
            className="huang-article" 
          > 
            <div className="huang-article__title">
              <h3>
                {item.name}
              </h3>
              <div id="accordion" className="accor-btn" onClick={click}>
                <span className="cross"></span>
              </div>
            </div>
            <div 
              className="huang-article__content"
              dangerouslySetInnerHTML={{__html: item.content}}
            >
            </div>
          </div>
        )
      })}
    </Swiper>
  )
}

class HuangWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: menuList,
      article: menuList[0].content,
      activeId: 0,
      setHeight: false
    }
    this.clickMenu = this.clickMenu.bind(this);
    this.openSection = this.openSection.bind(this);
  }

  clickMenu(index) {
    this.setState({
      article: menuList[index].content,
      activeId: index
    });
  }
  openSection() {
    let section = document.getElementsByClassName('huang-swiper-container')[0];
    if (this.state.setHeight) {
      section.style.maxHeight = '640px'
      section.classList.remove('active')
    } else {
      section.style.maxHeight = '100%';
      section.classList.add('active')
    }
    this.setState({setHeight: !this.state.setHeight})
  }

  render () {
    const { article, activeId } = this.state

    const renderMenu = menuList.map((item, index) =>
      <li 
        key={ item.id } 
        onClick={() => this.clickMenu(index) 
      }>
        <h3 className={index === activeId ? 'active' : ''}>
          { item.name }
        </h3>
      </li>
    );

    return (
      <Fragment>
        <div className='huang-work huang-work-pc'>
          <div className='huang-work-pc__menu'>
            <div className='huang-work-pc__menu__title'>
              <img src={WorkTextHuang} alt="五百棵檸檬樹" />
            </div>
            <ul>
              { renderMenu }
            </ul>
            <h6>完整篇章收錄於台北市立美術館出版《五百棵檸檬樹》（黃博志著，2018）</h6>
          </div>
          <div 
            className='huang-work-pc__content custom-scroll scroll-dark'
            dangerouslySetInnerHTML={{__html: article}}
          >
          </div>
        </div>
        <div className='huang-work huang-work-mobile'>
          <div className='huang-work-mobile__menu__title'>
            <img src={WorkTextHuang} alt="五百棵檸檬樹" />
          </div>
          <SimpleSwiperWithParams 
            click={this.openSection}
            menuList={menuList}
          />
        </div>
      </Fragment>
    );
  }
}

export default HuangWork;