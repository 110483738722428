import React, { Component } from 'react';

import '../../scss/common/artist-card.scss';

class artistCard extends Component {
  constructor(props){
    super(props);
    this.state= {
      currentId: props.id
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
  	this.props.click(this.state.currentId)
  }
  
  render() {
    const {
      backgroundImage,
      borderColor,
      artistEn,
      artistTW,
      title,
      active,
      id
    } = this.props;
  
    const style = {
      borderColor: {borderColor}
    };
  
    const blurStyle = {
      borderColor: {borderColor},
      backgroundImage: `url(${backgroundImage})`
    };

    const activeArtist = (active === id) && 'active'

    return (
      <div 
        className={`artist-card ${activeArtist}`}
        style={style}
        onClick={this.handleClick}
      >
        <div className="artist-card__blur" style={blurStyle}></div>
        <h3>{ artistEn }</h3>
        <div className="artist-card__name">
          <h5>{ title }</h5>．<h5>{ artistTW }</h5>
        </div>
      </div>
    );
  }
}
  
export default artistCard;
