import React from 'react';

import '../../scss/common/marquee.scss';

const MarqueeText = ({ text }) => {
  return (
    <span dangerouslySetInnerHTML={{__html: text}}></span>
  );
}

const Marquee = ({ id, text }) => {
  let rows = [];
  for(let i=0; i<10; i++) {
    rows.push(<MarqueeText text={text} key={i} />);
  }

  return (
    <div className="marquee" id={id}>
      <div className="marquee__wrapper">{rows}</div>
    </div>
  );
}
  
export default Marquee;
