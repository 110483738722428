import React from 'react';

import '../../../scss/index/main.scss';

import Intro from  './intro'
import Works from  './works'

const Main = () => {
  return (
    <div id="main">
        <Intro />
        <Works />
    </div>
  );
}

export default Main;
