import React, { Fragment } from 'react';

import '../../scss/common/modal-header.scss';

import lemonIcon from '../../images/lemon-icon.png';
import crossIcon from '../../images/cross.png';

import Marquee from  '../common/marquee'

const ModalHeader = (props) => {
  const { handleClose, folder, file } = props;
  const location = `Location://${folder}/<span>${file}</span>`
  return (
    <Fragment>
      <div className="modal-header">
        <div className="breadcrumb">
          <img src={lemonIcon} alt="lemon-icon" />
          <h1>Location://<span>{ folder }/</span><span>{ file }</span></h1>
        </div>
        <button onClick={handleClose}>
          <img src={crossIcon} alt="cross-icon" />
        </button>
      </div>
      <div className="fix-wrapper">
        <Marquee 
          id="sub-marquee"
          text={location} 
        />
      </div>
    </Fragment>
  );
}

export default ModalHeader;
